import request from "../../RequestUtil.js";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Completions from "./Charts/Completions";
import PieChart from "./Charts/PieChart";
import BarChart from "./Charts/BarChart";
import TextField from "@mui/material/TextField";
import DateRangeInput from "../../Components/DateRangeInput";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ActiveLastBreadcrumb, {
  BreadcrumbsLink,
} from "../../Components/ActiveLastBreadcrumb";

const PASS_MARK = 30;
const PERFECT_MARK = 80;

function getCompletions(data) {
  let invited = 0;
  let inProgress = 0;
  let completed = 0;

  for (const { completed_dt, started_dt } of data) {
    if (completed_dt) {
      ++completed;
    } else if (started_dt) {
      ++inProgress;
    } else {
      ++invited;
    }
  }
  return [invited, inProgress, completed];
}

function getPie(data) {
  let likely = 0;
  let wildcard = 0;
  let unlikely = 0;

  for (const { score_value } of data) {
    if (score_value > PERFECT_MARK) {
      ++likely;
    } else if (score_value > PASS_MARK) {
      ++wildcard;
    } else {
      ++unlikely;
    }
  }

  return [likely, wildcard, unlikely];
}

function getBar(data) {
  const result = new Array(10).fill(0);

  for (const { score_value } of data) {
    if (score_value === 0) {
      ++result[0];
    } else {
      const index = Math.floor((score_value - 1) / 10);
      ++result[index];
    }
  }

  return result;
}

export default function ClientResult() {
  const [data, setData] = useState(null);
  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());

  useEffect(() => {
    let skip = false;
    async function getData() {
      const response = await request({
        method: "post",
        data: { assessment_id: -1 },
        url: "/api/firm/search",
        responseType: "json",
      });
      if (skip) {
        return;
      }
      const newData = response.data.tableData.map(
        ({ created_dt, completed_dt, started_dt, score_value }) => {
          return {
            created_dt: new Date(created_dt),
            completed_dt,
            started_dt,
            score_value,
          };
        }
      );

      for (let i = 0; i < 20; ++i) {
        newData[i].started_dt = new Date().toString();
        newData[i].completed_dt = null;
      }

      for (let i = 20; i < 30; ++i) {
        newData[i].score_value = 85;
      }
      for (let i = 30; i < 35; ++i) {
        newData[i].score_value = 95;
      }

      setData(newData);
      setMinDate(Math.min(...newData.map((element) => element.created_dt)));
      setMaxDate(Math.max(...newData.map((element) => element.created_dt)));
      setFilterFromDate(
        Math.min(...newData.map((element) => element.created_dt))
      );
      setFilterToDate(
        Math.max(...newData.map((element) => element.created_dt))
      );
    }

    getData();
    return () => {
      skip = true;
    };
  }, []);

  if (!data) {
    return null;
  }

  const filteredData = data.filter(
    (element) =>
      element.created_dt >= filterFromDate && element.created_dt <= filterToDate
  );
  const completions = getCompletions(filteredData);
  const pie = getPie(filteredData);
  const bar = getBar(filteredData);

  const [invited, inProgress, completed] = completions;
  const [likely, wildcard, unlikely] = pie;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          {/* <ActiveLastBreadcrumb>
          <BreadcrumbsLink href="/firm/dash">Dashboard</BreadcrumbsLink>
          <BreadcrumbsLink href="/firm/search">
            Test Group | OCI
          </BreadcrumbsLink>
          <BreadcrumbsLink last>Total Assesment Usage</BreadcrumbsLink>
        </ActiveLastBreadcrumb> */}
        </Grid>
        <Grid item lg={6}>
          <DateRangeInput
            fromLabel="From"
            toLabel="to"
            fromDate={filterFromDate}
            toDate={filterToDate}
            minDate={minDate}
            maxDate={maxDate}
            onFromDateChange={(value) => setFilterFromDate(value)}
            onToDateChange={(value) => setFilterToDate(value)}
            inputFormat="MM/dd/yy"
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item lg={12}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              mb: 2,
              padding: "20px 40px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={2} />
              <Grid item lg={8}>
                <Completions
                  invited={invited}
                  in_progress={inProgress}
                  completed={completed}
                />
              </Grid>
              <Grid item lg={2} />
            </Grid>
          </Paper>
        </Grid>
        {/* <Grid item lg={4}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              mb: 2,
              padding: "20px 30px",
            }}
          >
            <PieChart likely={likely} wildcard={wildcard} unlikely={unlikely} />
          </Paper>
        </Grid> */}
        <Grid item lg={12}>
          <Paper
            sx={{ width: "100%", height: "100%", mb: 2, padding: "20px 40px" }}
          >
            <BarChart scores={bar} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
