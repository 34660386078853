import _ from "lodash";
import React, { Component } from "react";
import OCIClient from "../Reports/OCIClient";
import { downloadReport } from "../snippets/utils";

class ServerReportContianer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waitingOnServerData: true
    }
  }

  componentDidMount = () => {
    // To trigger the event Listener
    document.addEventListener("render-report", () => {
      this.setState({ 
        waitingOnServerData: false 
      }, async () => {

        const { fileName } = window.data;
        await downloadReport(fileName);
      });
    });
  }

  getReportComponent = () => {
    const { reportData, reportId, firm } = window.data;
    const scoreType = _.get(firm, "scoreType", "numeric");

    switch (reportId) {
      case 1:
        return <OCIClient data={reportData} scoreType={scoreType} />;
        break;
    }
  };

  render() {
    const { waitingOnServerData } = this.state;

    if(waitingOnServerData) {
      return <div>Waiting on server data</div>
    }

    const ReportComponent = this.getReportComponent();
    return (
      <div>
        {ReportComponent}
      </div>
    )
  }
}

export default ServerReportContianer;
