import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Typography,
    Grid,
    Tabs,
    Tab
} from "@mui/material";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import Loader from "../../../Components/Loader";
import { useHistory, useLocation } from 'react-router-dom';
import NormTable from "./NormTable";
import { getScoringConstants } from "../../../services/scoring-constants";
import { downloadFile } from "../../../helper/helper";

const NormVersions = ({ user }) => {
    const [normVersions, setNormVersions] = useState([]);
    const [normVersionLoading, setNormVersionLoading] = useState(true)

    const history = useHistory();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const version = +searchParams.get('version') || normVersions?.[0]?.version;

    useEffect(async () => {
        try {
            setNormVersionLoading(true);
            const response = await getScoringConstants({
                assessmentType: 1
            });

            setNormVersions(response.rows);
            setNormVersionLoading(false);
        } catch (error) {
            console.error("Failed to fetch norm versions:", error);
            setNormVersionLoading(false);
        }
    }, []);

    const handleTabChange = (event, value) => {
        history.push(`?version=${value}`)
    };

    const downloadCSV = (norms) => {
        if(!norms) {
            window.alert("Error: norms not available to download");
        }

        let csvContent = "data:text/csv;charset=utf-8,";

        // Assuming norms.jsonData is an array of objects
        if (norms?.jsonData?.length > 0) {
            // Extract keys from the first object to create the header
            const header = Object.keys(norms.jsonData[0]).join(",");
            csvContent += header + "\n";

            // Iterate over each object to create rows
            norms.jsonData.forEach(row => {
                const rowContent = Object.values(row).join(",");
                csvContent += rowContent + "\n";
            });
        }

        const exportData = _.get(norms, "jsonData", []).map((norm) => ({
            "indicator_id": norm.indicator_id,
            "indicator_name": norm.indicator_name,
            "indicator_identifier": norm.indicator_identifier,
            "likert_mean": norm.likert_mean,
            "likert_sd": norm.likert_sd,
            "updatedAt": norm.updatedAt,
            "updatedBy": norm.updatedBy,
            "createdBy": norm.createdBy,
            "createdAt": norm.createdAt,
        }));

        downloadFile({
            exportData, 
            filename: `norm_version_${version}.csv`
        });
    };

    const handleCreate = () => {
        history.push(`/admin/norms/create`);
    }

    const childRef = useRef();
    const selectedNorms = normVersions.find(norm => norm.version == version);

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                sx={{ mb: 1 }}
            >
                <Typography variant="h4">
                    Norm Versions
                </Typography>
                <div>
                    <Button
                        variant="contained"
                        sx={{ marginRight: 1 }}
                        onClick={handleCreate}
                    >
                        <AddIcon /> Add new
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ marginRight: 0 }}
                        onClick={() => {
                            downloadCSV(childRef.current.getNorms());
                        }}
                    >
                        <DownloadIcon /> Download all
                    </Button>
                </div>
            </Grid>
            {normVersionLoading && <Loader minHeight={"70vh"} />}
            {
                !normVersionLoading && <>
                    <Tabs
                        value={version}
                        onChange={handleTabChange}
                        // centered
                        textColor="secondary"
                        indicatorColor="secondary"

                        style={{ marginBottom: "2px" }}
                    >
                        {
                            normVersions?.map((normVersion, ind) => {
                                return (
                                    <Tab
                                        key={ind} 
                                        label={`Norms ${normVersion.version}`} 
                                        value={normVersion.version} 
                                    />
                                )
                            })
                        }
                    </Tabs>

                    <NormTable
                        ref={childRef}
                        scoringConstantId={selectedNorms?.constId}
                        showEditOptions={true}
                        user={user}
                    />
                </>
            }
        </>
    );
};

export default NormVersions;
