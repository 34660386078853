import ErrorWrapper from "./ErrorWrapper";
import Image from "../../images/404.svg";

const Error404 = () => {
  return (
    <ErrorWrapper>
      <div className="content">
        <div className="content-inner">
          <h1>404</h1>
          <h2>Oops! Page not found.</h2>
          <p>
            Sorry, the page you were looking for could not be found. Please try
            navigating back to the previous page, or getting in touch at{" "}
            <a href="mailto:helpdesk@thine.co">helpdesk@thine.co</a>.
          </p>
        </div>
      </div>
      <div className="image">
        <img src={Image} alt="404 error not found illustration" />
      </div>
    </ErrorWrapper>
  );
};

export default Error404;
