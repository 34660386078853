import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { Stack, Typography } from "@mui/material";

export default function DateRangeInput(props) {
  const {
    fromDate,
    onFromDateChange,
    toDate,
    onToDateChange,
    fromLabel,
    toLabel,
    ...others
  } = props;

  return (
    <Stack spacing={2} direction="row">
      <Typography component="span" sx={{ lineHeight: 3.3 }}>
        Date&nbsp;Range:
      </Typography>
      <DesktopDatePicker
        {...others}
        label={fromLabel}
        value={fromDate}
        onChange={onFromDateChange}
      />
      <Typography component="span" sx={{ lineHeight: 3.3 }}>
        -
      </Typography>
      <DesktopDatePicker
        {...others}
        label={toLabel}
        value={toDate}
        minDate={fromDate}
        onChange={onToDateChange}
      />
    </Stack>
  );
}
