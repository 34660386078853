import React, { Component } from "react";
import _ from "lodash";

import { Link } from "react-router-dom";
import TabPanel from "../../../Components/Tabpanel";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NormTable from "../Norms/NormTable";
import { getJobs } from "../../../services/jobs";
import { getFirmClean } from "../../../services/firm";
import Loader from "../../../Components/Loader";
import { Typography } from "@mui/material";

class JobOverview extends Component {
  constructor(props) {
    super(props);

    this.jobId = this.props.match.params.jobId;
    this.state = {
      isLoading: true,
      jobDetails: {},
      clientDetails: {},
    };
  }

  async componentDidMount() {
    try {
      const jobDetailsResponse = await getJobs({
        id: this.jobId
      });

      const jobDetails = _.get(jobDetailsResponse, "0", {});

      const clientResponse = await getFirmClean({
        clientId: jobDetails.pool.clientId
      });

      this.setState({
        isLoading: false,
        jobDetails,
        clientDetails: _.get(clientResponse, "rows.0", {}),
      });
    } catch (error){
      console.log("error: ", error);
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { isLoading, jobDetails, clientDetails } = this.state;
    const { logoUrl, clientDescription } = clientDetails;

    return (
      <>
        {
          isLoading ?
          <Loader minHeight={"70vh"} /> :
          <div>

            <Box sx={{ ml: 4, mb: 2 }}>
              <b>Job Name: {jobDetails.title}</b>
            </Box>

            <Grid
              container
              spacing={0}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={2}>
                <img src={logoUrl} style={{ maxWidth: "100px" }} />
              </Grid>
              <Grid item xs={9}>
                <p>{clientDescription}</p>
              </Grid>
            </Grid>
            
            <h1>Job Search Overview</h1>
            
            
            <BasicTabs 
              jobDetails={jobDetails}
              clientDetails={clientDetails}
            />
          </div>
        }
      </>
    );
  }
}

function BasicTabs({ jobDetails, clientDetails }) {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Candidate Overview" />
          <Tab label="Norms" />
          <Tab label="Assessment" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        TODO
      </TabPanel>
      <TabPanel value={value} index={1}>
        {
          jobDetails.scoringConstantId ?
          <NormTable
            scoringConstantId={jobDetails.scoringConstantId}
          /> : <div>No norms are attached to this job search</div>
        }
      </TabPanel>
      <TabPanel value={value} index={2}>
        TODO
      </TabPanel>
    </Box>
  );
}

export default JobOverview;
