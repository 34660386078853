import React from "react";
import { newColorMap, MIN_BAR_WIDTH } from "./constants";

const CandidateDimensions = (props) => {
  const children = props.dimensions.map((dim, i) => {
    const widthProp =
      dim.individualScore < 10
        ? MIN_BAR_WIDTH + "%"
        : dim.individualScore + "%";
    const normWidth = dim.populationScore + "%";
    const bgClassName = "bg-" + dim.color;
    return (
      <div key={i + 1} className="dimension-meters">
        <div className="text-contain">
          <span className="label" style={{ color: newColorMap[dim.color] }}>
            {dim.name}
          </span>
          <span className="description">{dim.description}</span>
        </div>
      </div>
    );
  });

  return <div className="fw-stack">{children}</div>;
};

export default CandidateDimensions;
