import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export default function BackgroundLetterAvatar(props) {
  const [menuEl, setMenuEl] = React.useState(null);
  const menuOpen = Boolean(menuEl);
  const handleClick = (event) => {
    setMenuEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuEl(null);
  };

  return (
    <>
      <Button
        // className={pathname === link.href ? "current-page" : ""}
        sx={{
          my: 2,
          textTransform: "none",
          color: "white",
          display: "block",
          "&.current-page": {
            backgroundColor: "rgba(255, 255, 255, 0.12)",
          },
          whiteSpace: "nowrap",
          minWidth: "max-content",
          padding: 0,
        }}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={menuEl ? "true" : undefined}
        onClick={handleClick}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <div className="mobile-hide">
            <Typography variant="body1">{props.name}</Typography>
          </div>
          <Avatar {...stringAvatar(props.name)} />
        </Stack>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={menuEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <NavLink to="/profile">
          <MenuItem
            sx={{
              color: "#25282b",
            }}
            onClick={handleClose}
          >
            Profile
          </MenuItem>
        </NavLink>
        <a href={`${props.baseUrl}/api/logout`}>
          <MenuItem
            sx={{
              color: "#25282b",
            }}
            onClick={handleClose}
          >
            Logout
          </MenuItem>
        </a>
      </Menu>
    </>
  );
}
