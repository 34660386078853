import React, { Component } from "react";
import request from "../../RequestUtil.js";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Loader from "../../Components/Loader";
import BasicSnackBar from "../../Components/Utilities/BasicSnackbar";

class PendingRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tab: 0,
      results: [],
      sendMessageResponse: {
        open: false,
        message: "",
        severity: "success",
      },
      statusMap: {
        0: "pending",
        1: "active",
        2: "inactive",
      },
      infoMap: {
        0: [
          `Below you will see all requests from Thine’s partner firms to see your assessment results that are still pending. Please select accept if you agree to have a given firm see your assessment results. If you do not want a given firm to see your assessment results, please select deny.`,
          `Please check with a firm’s recruiting team to see if taking Thine’s assessment is a requirement.`,
        ],
        1: [
          "Below you will see all the firms that you have approved to see your assessment results. If for any reason you would like to update these approvals, please select deny below.",
        ],
        2: [
          "Below you will see all the firms that you have denied to see your assessment results. If for any reason you would like to update these approvals, please select approve below. ",
        ],
      },
    };
  }

  componentDidMount() {
    this.fetchPageData();
  }

  fetchPageData = () => {
    const { tab, statusMap } = this.state;
    const { handleResponse } = this.props;

    this.setState(
      {
        isLoading: true,
        results: [],
      },
      () => {
        request({
          method: "get",
          responseType: "json",
          url: `/api/candidate/approvals?status=${statusMap[tab]}`,
        })
          .then((response) => {
            console.log(response);
            this.setState(
              {
                isLoading: false,
                results: response.data,
              },
              () => {
                // handle in case of home page, we need to open blocking pop-up
                handleResponse && handleResponse(response.data);
              }
            );
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              isLoading: false,
              sendMessageResponse: {
                ...this.state.sendMessageResponse,
                open: true,
                message: "Something Went Wrong!",
                severity: "error",
              },
            });
          });
      }
    );
  };

  handleStatusChange = async (id, status) => {
    request({
      url: `/api/client-candidate-assessment-mapping/${id}`,
      method: "put",
      data: {
        status,
      },
    })
      .then((response) => {
        this.setState(
          {
            sendMessageResponse: {
              ...this.state.sendMessageResponse,
              open: true,
              message: "Request updated successfully!",
              severity: "success",
            },
          },
          () => {
            this.fetchPageData();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          sendMessageResponse: {
            ...this.state.sendMessageResponse,
            open: true,
            message: "Something Went Wrong!",
            severity: "error",
          },
        });
      });
  };

  handleTabChange = async (event, newValue) => {
    this.setState(
      {
        tab: newValue,
      },
      () => {
        this.fetchPageData();
      }
    );
  };

  render() {
    const { isLoading, results, tab, infoMap } = this.state;
    const { hideTabs, hideAlert, subtitle, hideTitle, isModal, hideHeader } =
      this.props;

    return (
      <div>
        {!hideTabs && (
          <Tabs
            value={tab}
            onChange={this.handleTabChange}
            centered
            style={{ marginBottom: "20px" }}
          >
            <Tab label="Pending" />
            <Tab label="Approved" />
            <Tab label="Denied" />
          </Tabs>
        )}
        <div className="card noMarginBottom">
          {!hideTitle && <h2>Share Your Results</h2>}
          {subtitle && (
            <Typography
              variant="subtitle1"
              sx={{ pl: "40px", color: "rgba(17, 17, 17, 0.7)" }}
            >
              {subtitle}
            </Typography>
          )}
          {!hideAlert && (
            <Alert severity="info" style={{ margin: "0px 10px 0px 10px" }}>
              {infoMap[tab].map((str, index) => {
                return (
                  <div>
                    {index > 0 && <br />}
                    {str}
                  </div>
                );
              })}
            </Alert>
          )}

          {isLoading ? (
            <Loader />
          ) : (
            <table style={{ margin: hideTitle ? "0px" : "10px auto" }}>
              {!hideHeader ? (
                <thead>
                  <tr>
                    {!isModal ? (
                      <>
                        <th>Assessment</th>
                      </>
                    ) : null}
                    <th>Client Name</th>
                    <th>Actions</th>
                    {!isModal ? <th></th> : null}
                  </tr>
                </thead>
              ) : null}
              <tbody>
                {results.length == 0 ? (
                  <tr style={{ textAlign: "center" }}>
                    <td colSpan="4">No pending requests present</td>
                  </tr>
                ) : (
                  results.map((result) => {
                    const {
                      id,
                      assignment_id,
                      assign_dt,
                      assessment_title,
                      client_name,
                      status,
                    } = result;
                    return (
                      <tr key={assignment_id}>
                        {!isModal ? <td>{assessment_title}</td> : null}
                        {/* <td>{moment(assign_dt).format("M/DD/YYYY")}</td> */}
                        <td>{client_name}</td>
                        <td>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={2}
                          >
                            {tab != 2 && (
                              <Button
                                variant="outlined"
                                sx={{
                                  "text-transform": "none!important",
                                  width: "96px",
                                  "font-weight": "bold",
                                  border: "1px #464747 solid!important",
                                  color: "#464747 !important",
                                }}
                                onClick={() =>
                                  this.handleStatusChange(id, "inactive")
                                }
                              >
                                Deny
                              </Button>
                            )}
                            {tab != 1 && (
                              <Button
                                variant="outlined"
                                color="success"
                                sx={{
                                  "text-transform": "none!important",
                                  width: "96px",
                                  "font-weight": "bold",
                                  border: "1px #464747 solid!important",
                                  color: "#464747 !important",
                                }}
                                onClick={() =>
                                  this.handleStatusChange(id, "active")
                                }
                              >
                                Approve
                              </Button>
                            )}
                          </Stack>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
        </div>

        <BasicSnackBar
          snackbarOpen={this.state.sendMessageResponse.open}
          setSnackbarOpen={(value) =>
            this.setState({
              sendMessageResponse: {
                ...this.state.sendMessageResponse,
                open: value,
              },
            })
          }
          message={this.state.sendMessageResponse.message}
          severity={this.state.sendMessageResponse.severity}
        />
      </div>
    );
  }
}

export default withRouter(PendingRequests);
