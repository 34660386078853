import React, { Component } from "react";
import axios from "axios";

import {
  Paper,
  FormGroup,
  FormControlLabel,
  List,
  ListItem,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";

class FilterSelector extends Component {
  // import into Create/Update Entry Level flow. Provide clientId and jobId as state props
  constructor(props) {
    super(props);

    this.state = {
      availableFilters: [],
      currentSelection: [],
    };
  }

  componentDidMount() {
    this.getAvailableFilters();
    this.getExistingFilterSelection();
  }

  getExistingFilterSelection = async () => {
    try {
      const { jobId } = this.props;
      const { data } = await axios({
        method: "get",
        url: "/api/filters/" + jobId,
        responseType: "json",
      });

      let currentSelection = data.map((row) => {
        return row.indicatorId;
      });

      this.setState({ currentSelection });
    } catch (err) {
      console.log(err);
      this.props.toggleAlert({
        alertMessage: "There was a problem retrieving the existing filters.",
        alertSeverity: "error",
      });
    }
  };

  getAvailableFilters = async () => {
    try {
      const { data: parentFilters } = await axios({
        method: "get",
        url: "/api/survey/indicators",
        responseType: "json",
      });

      let availableFilters = [];
      for (let i = 0; i < parentFilters.length; i++) {
        for (let j = 0; j < parentFilters[i].children.length; j++) {
          availableFilters.push(parentFilters[i].children[j]);
        }
      }

      this.setState({ availableFilters });
    } catch (err) {
      console.log(err);
    }
  };

  checkIndicator = (id) => {
    let selection = [...this.state.currentSelection];
    if (selection.includes(id)) {
      // remove
      const position = selection.indexOf(id);
      selection.splice(position, 1);
      this.setState({ currentSelection: selection });
    } else {
      // add if 8 filters have not been reached
      if (selection.length < 8) {
        selection.push(id);
        this.setState({ currentSelection: selection });
      }
    }
  };

  submitSelection = async () => {
    const { currentSelection: filtersArray } = this.state;
    const { jobId, clientId } = this.props;
    try {
      const submission = await axios({
        method: "post",
        url: "/api/filters/create",
        data: {
          filtersArray,
          jobId,
          clientId,
        },
        responseType: "json",
      });
      this.props.toggleAlert({
        alertMessage: "Filters have been successfully updated.",
        alertSeverity: "success",
      });
      this.props.complete();
    } catch (err) {
      console.log(err);
      this.props.toggleAlert({
        alertMessage: "There was a problem updating the filter selection.",
        alertSeverity: "error",
      });
    }
  };

  render() {
    let { currentSelection, availableFilters } = this.state;

    let filters = availableFilters.map((filter, i) => {
      let checked = currentSelection.includes(filter.indicator_id);
      return (
        <ListItem disablePadding={true}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onClick={() => {
                  this.checkIndicator(filter.indicator_id);
                }}
              ></Checkbox>
            }
            label={filter.indicator_name}
          ></FormControlLabel>
        </ListItem>
      );
    });

    return (
      <div>
        <Typography variant="h2">{currentSelection.length}/8</Typography>
        {currentSelection.length === 8 ? (
          <Button variant="contained" onClick={this.submitSelection}>
            Submit
          </Button>
        ) : null}

        <FormGroup component={Paper}>
          <List dense={true}>{filters}</List>
        </FormGroup>
      </div>
    );
  }
}

export default FilterSelector;
