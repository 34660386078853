import React, { Component } from "react";

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      position: this.props.position,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.position !== prevProps.position) {
      this.forceUpdate();
    }
  }

  render() {
    return (
      <div className="sliding-scale">
        <div className="scale-contain">
          <input
            type="range"
            min="1"
            max="5"
            value={this.props.position}
            className="scale"
            id="scale"
            name="scale"
            step="1"
            onChange={this.props.handleSlide}
            onInput={this.props.handleSlide}
          ></input>
        </div>

        <div className="scale-labels">
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>5</span>
        </div>
      </div>
    );
  }
}

export default Slider;
