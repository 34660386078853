import {Route} from "react-router-dom";
import {DebugMain} from "./DebugMain";
import {DebugAssignment} from "./DebugAssignment";
import {DebugAssessment} from "./DebugAssessment";
import React from "react";


// respect REACT_APP_FEATURE_ADMIN_DEBUG if it exists, else enable in development
const enableAdminDebugRoutes = process.env.REACT_APP_FEATURE_ADMIN_DEBUG ? process.env.REACT_APP_FEATURE_ADMIN_DEBUG === "true" : process.env.NODE_ENV === "development";

export function AdminDebugRoutes() {
    if (!enableAdminDebugRoutes) {
      return null;
    }

    return (
        <>
            <Route exact path="/admin/debug" component={() => <DebugMain/>}/>
            <Route exact path="/admin/debug/assignment/:assignment_id" component={DebugAssignment}/>
            <Route exact path="/admin/debug/assessment/:assessment_id" component={DebugAssessment}/>
        </>
    )
}
