import request from "../RequestUtil.js";
import moment from "moment";
import React, { Component } from "react";

import Assessment from "../Components/AssessmentTake/Assessment";

class AssignedAssessments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      selectedAssignment: null,
    };

    this.takeAssessment = this.takeAssessment.bind(this);
  }

  takeAssessment(id) {
    this.setState({ stage: 1, selectedAssignment: id });
  }

  render() {
    return (
      <div>
        <div>
          <h1>My Assessments</h1>
          {this.state.stage === 0 ? (
            <AssessmentList takeAssessment={this.takeAssessment} />
          ) : null}

          {/* just for now */}
          {this.state.stage === 1 ? (
            <Assessment assign_id={this.state.selectedAssignment} />
          ) : null}
        </div>
      </div>
    );
  }
}

class AssessmentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
    };
  }

  componentDidMount() {
    request({
      method: "get",
      url: "/api/assessments/user/assigned",
    })
      .then((res) => {
        this.setState({ list: res.data });
      })
      .catch((err) => console.log(err));
  }

  render() {
    let rows = this.state.list.map((row, i, arr) => {
      return (
        <tr key={i} className={i % 2 === 0 ? "even" : "odd"}>
          <td>{row.assessment_title}</td>
          <td>
            <i>{row.assessment_description}</i>
          </td>
          <td>{moment(row.assign_dt).format("MMM Do, YYYY")}</td>
          <td>{row.assigned_by}</td>
          <td>
            <button
              className="button"
              onClick={() => {
                this.props.takeAssessment(row.assignment_id);
              }}
            >
              {/* pencil iconplace */}
              Take
            </button>
          </td>
        </tr>
      );
    });
    return (
      <div className="card">
        <table>
          <thead>
            <tr>
              <th>Assessment Name</th>
              <th>Description</th>
              <th>Assigned On</th>
              <th>Assigned By</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

export default AssignedAssessments;
