import React, { useEffect, useState } from "react";
import MyAssessments from "../Components/MyAssessments";
import EditModal from "../Components/EditModal";
import ClientDashboard from "../pages/Client/ClientDashboard";
import ClientList from "./Admin/Clients/ClientList";
import PendingRequests from "./Candidate/PendingRequests";
import request from "../RequestUtil.js";
import { Box, CircularProgress } from "@mui/material";

const Dash = ({ user }) => {
  const [permissions, setPermissions] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [showRequestModel, setShowRequestModel] = useState(false);

  useEffect(() => {
    request({
      method: "post",
      url: "/api/users/check",
      responseType: "json",
    })
      .then((response) => {
        setPermissions(response.data.scope_result);
        setAdmin(response.data.admin);
      })
      .catch((err) => console.log(err));
  }, []);

  const DashboardController = () => {
    if (admin) {
      return <ClientList user={user} />;
    } else if (!permissions) {
      return <CircularProgress />;
    } else if (permissions.length > 0) {
      return <ClientDashboard user={user} />;
    } else {
      return (
        <>
          <MyAssessments />
          <EditModal
            minWidth={"60vw"}
            handleCancel={() => setShowRequestModel(false)}
            contentPaddingLeft={0}
            title="Share Your Results"
            subTitle="The following firms have requested access to view your assessment results."
            isBlocking={true}
            style={{
              visibility: showRequestModel ? "visible" : "hidden",
            }}
          >
            <PendingRequests
              isModal={true}
              hideHeader={true}
              hideTitle={true}
              hideTabs={true}
              hideAlert={true}
              handleResponse={(response) => {
                setShowRequestModel(response.length ? true : false);
              }}
            />
          </EditModal>
        </>
      );
    }
  };

  return (
    <Box sx={{ ml: 1.8 }}>
      <DashboardController />
    </Box>
  );
};

export default Dash;
