import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y",
  plugins: {
    title: {
      display: true,
      text: "Candidate Completions",
      position: "top",
      align: "start",
      padding: {
        bottom: 30,
      },
    },
    legend: {
      position: "bottom",
      align: "start",
      display: true,
      labels: {
        usePointStyle: true,
      },
    },
  },
  height: 30,
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = [""];

export default function Completions({ invited, in_progress, completed }) {
  const data = {
    labels,
    datasets: [
      {
        label: "Invited",
        data: [invited],
        backgroundColor: "#45aae1",
        stack: "Stack 0",
      },
      {
        label: "In Progress",
        data: [in_progress],
        backgroundColor: "#5d2a5f",
        stack: "Stack 0",
      },
      {
        label: "Completed",
        data: [completed],
        backgroundColor: "#42b0a9",
        stack: "Stack 0",
      },
    ],
  };

  return <Bar options={options} data={data} />;
}
