import { Box, TextField, Typography } from "@mui/material";
import { isValidEmail } from "../../helper/helper.js"
import React from "react";

const BasicTextInput = ({
  name,
  title,
  hideTitle = false,
  placeholder,
  value,
  onChange,
  style,
  disabled = false,
  type = null,
}) => {
  const [error, setError] = React.useState({
    msg: "",
    error: false,
  });

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current      function is done)
    setTimeout(e.target.focus, 0);
  }

  const handleChange = (e) => {
    if (!e.target.value) {
      setError({
        error: true,
        msg: `${title.toLowerCase()} cannot be empty`,
      });
      onChange(e);
    } else {
      if (type === "email" && !isValidEmail(e.target.value)) {
        setError({
          error: true,
          msg: "Please enter a valid email address",
        });
      } else {
        setError({
          error: false,
          msg: "",
        });
      }
      onChange(e);
    }
  };
  
  return (
    <Box sx={{ marginBottom: 2, ...style }}>
      {
        !hideTitle && <Typography
          variant="subtitle2"
          style={{ fontWeight: 600 }}
          component="div"
        >
          {title}
        </Typography>
      }
      <TextField
        disabled={disabled}
        id="outlined-search"
        placeholder={placeholder}
        error={error.error}
        inputProps={{ "aria-label": "Without label" }}
        sx={{ m: 1, ml: 0, mt: 2, minWidth: 400, width: 500 }}
        value={value}
        name={name}
        onChange={handleChange}
        type={type}
        onWheel={numberInputOnWheelPreventChange}
      />
      {error.error && (
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ color: "#d32f2f" }}
        >
          {error.msg}
        </Typography>
      )}
    </Box>
  );
};

export default BasicTextInput;
