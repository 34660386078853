import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import BasicTextarea from "../../../Components/Inputs/BasicTextarea";
import BasicTextInput from "../../../Components/Inputs/BasicTextInput";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import BasicSnackbar from "../../../Components/Utilities/BasicSnackbar";
import BasicSelect from "../../../Components/Inputs/BasicSelect";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { updateFirm } from "../../../services/firm";
import { fetchFirmSession } from "../../../redux/actions/firm"

const scoreTypes = [
  { value: "numeric", displayText: "Numeric" },
  // { value: "buckets", displayText: "Buckets" },
];

const ClientSettings = ({ fetchFirmSession }) => {
  const [editingMode, setEditingMode] = useState(false);
  const logoInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({
    logo: "",
    firmName: "",
    firmDescription: "",
    scoreType: "numeric",
    isDailyEmailEnabled: 0,
    coachFeatureEnabled: false,
    clientId: 0,
  });
  const [newSettings, setNewSettings] = useState({
    firmName: "",
    firmDescription: "",
    scoreType: "numeric",
    isDailyEmailEnabled: 0,
    coachFeatureEnabled: 0,
  });
  const [saveResponse, setSaveResponse] = useState({
    message: "",
    open: false,
    severity: "success",
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/firm");
      const json = await response.json();

      setSettings((s) => ({
        ...s,
        logo: json.logoUrl,
        firmName: json.clientName,
        firmDescription: json.clientDescription || "",
        scoreType: json.scoreType || "numeric",
        isDailyEmailEnabled: json.isDailyEmailEnabled || 0,
        coachFeatureEnabled: json.coachFeatureEnabled || false,
        clientId: json.clientId,
      }));

      setNewSettings((s) => ({
        ...s,
        firmName: json.clientName,
        firmDescription: json.clientDescription || "",
        scoreType: json.scoreType || "numeric",
        isDailyEmailEnabled: json.isDailyEmailEnabled || 0,
        coachFeatureEnabled: json.coachFeatureEnabled || 0,
      }));
    };

    fetchData();
  }, [saveResponse]);

  const previewLogo = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => setSettings({ ...settings, logo: reader.result });
  };

  const uploadLogo = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch("/api/firm/logo/", {
      method: "POST",
      body: formData,
    });

    return response.status === 200;
  };

  const saveNewSettings = async (file, clientId) => {
    setLoading(true);
    const logoUpdateReponse = await uploadLogo(file);

    if (logoUpdateReponse) {
      const updateResponse = await updateFirm(clientId, {
        clientName: newSettings.firmName,
        clientDescription: newSettings.firmDescription,
        scoreType: newSettings.scoreType,
        isDailyEmailEnabled: newSettings.isDailyEmailEnabled ? 1 : 0,
        coachFeatureEnabled: newSettings.coachFeatureEnabled || false,
      });

      if (updateResponse.status === 200) {
        fetchFirmSession();
        setSaveResponse({
          ...saveResponse,
          message: "successfully updated settings",
          open: true,
          severity: "success",
        });
      }

      setEditingMode(false);
      setLoading(false);
    } else {
      setSaveResponse({
        ...saveResponse,
        message: "failed to upload new logo",
        open: true,
        severity: "error",
      });

      setEditingMode(false);
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 800,
        p: 2,
      }}
    >
      <Alert severity="info" sx={{ "margin-bottom": "25px" }}>
        Your firm’s description will be used in outreach emails to associates
        and candidates. This could be an additional opportunity to provide
        insight into the firm’s culture and what makes your firm unique.{" "}
      </Alert>
      <BasicSnackbar
        message={saveResponse.message}
        snackbarOpen={saveResponse.open}
        setSnackbarOpen={(value) =>
          setSaveResponse({ ...saveResponse, open: value })
        }
        severity={saveResponse.severity}
      />
      {editingMode ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "right",
            }}
          >
            {loading ? <CircularProgress sx={{ mr: 2 }} /> : null}
            <Button
              variant="contained"
              onClick={() =>
                saveNewSettings(logoInput.current.files[0], settings.clientId)
              }
            >
              <SaveIcon sx={{ mr: 1 }} />
              Save Changes
            </Button>
          </Box>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            component="div"
          >
            Logo
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", mb: 6.4, mt: 2 }}>
            <img src={settings.logo} alt="Firm Logo" style={{ width: "50%" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 1,
                ml: 3.2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                ref={logoInput}
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                style={{ display: "none" }}
                onChange={() => previewLogo(logoInput.current.files[0])}
              />
              <Button
                variant="outlined"
                component="span"
                onClick={() => {
                  logoInput.current.click();
                }}
              >
                <FileUploadIcon sx={{ mr: 1 }} />
                Upload New Logo
              </Button>
            </Box>
          </Box>
          <BasicTextInput
            title="Firm Name"
            placeholder="Enter Firm Name..."
            value={newSettings.firmName}
            onChange={(e) =>
              setNewSettings({ ...newSettings, firmName: e.target.value })
            }
            sx={{ mb: 3.2 }}
          />
          <BasicTextarea
            value={newSettings.firmDescription}
            onChange={(value) => {
              setNewSettings({ ...newSettings, firmDescription: value });
            }}
            title="Firm Description"
            placeholder={"Enter Firm description..."}
            textLimit={1000}
            rows={7}
            sx={{ width: "fit-content" }}
          />
          <BasicSelect
            options={scoreTypes}
            title="Score Type"
            value={newSettings.scoreType}
            onChange={(e) => {
              setNewSettings({ ...newSettings, scoreType: e.target.value });
            }}
          />
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            component="div"
          >
            Notifications
          </Typography>
          <FormGroup>
            <FormControlLabel 
              label="Receive daily recap emails with all assessment completions"
              control={
                <Checkbox
                  checked={newSettings.isDailyEmailEnabled == true}
                  onChange={(event) => {
                    setNewSettings({ 
                      ...newSettings, 
                      isDailyEmailEnabled: event.target.checked 
                    });
                  }}
                  inputProps={{
                    "aria-label": "Receive daily recap emails with all assessment completions",
                  }}
                />
              } 
            />
          </FormGroup>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            component="div"
          >
            Feature Flags
          </Typography>
          <FormGroup>
            <FormControlLabel 
              label="Allow developmental assessment lawyer overview"
              control={
                <Checkbox
                  checked={newSettings.coachFeatureEnabled == true}
                  onChange={(event) => {
                    setNewSettings({ 
                      ...newSettings, 
                      coachFeatureEnabled: event.target.checked 
                    });
                  }}
                  inputProps={{
                    "aria-label": "Developmental Assessment Lawyer Overview",
                  }}
                />
              } 
            />
          </FormGroup>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "right",
            }}
          >
            <Button variant="contained" onClick={() => setEditingMode(true)}>
              <EditIcon sx={{ mr: 1 }} />
              Edit
            </Button>
          </Box>
          
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            component="div"
          >
            Logo
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", mb: 6.4, mt: 2 }}>
            <img src={settings.logo} alt="Firm Logo" style={{ width: "50%" }} />
          </Box>
          <BasicTextInput
            disabled={true}
            title="Firm Name"
            placeholder="Enter Firm Name..."
            value={newSettings.firmName}
            onChange={(e) =>
              setNewSettings({ ...newSettings, firmName: e.target.value })
            }
            sx={{ mb: 3.2 }}
          />
          <BasicTextarea
            disabled={true}
            value={newSettings.firmDescription}
            onChange={(value) => {
              setNewSettings({ ...newSettings, firmDescription: value });
            }}
            title="Firm Description"
            placeholder={"Enter Firm description..."}
            textLimit={1000}
            rows={7}
            sx={{ width: "fit-content" }}
          />
          <BasicSelect
            disabled={true}
            options={scoreTypes}
            title="Score Type"
            value={newSettings.scoreType}
            onChange={(e) => {
              setNewSettings({ ...newSettings, scoreType: e.target.value });
            }}
          />
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            component="div"
          >
            Notifications
          </Typography>
          <FormGroup>
            <FormControlLabel 
              label="Receive daily recap emails with all assessment completions"
              control={
                <Checkbox
                  disabled={true}
                  checked={newSettings.isDailyEmailEnabled == true}
                  onChange={(event) => {
                    setNewSettings({ 
                      ...newSettings, 
                      isDailyEmailEnabled: event.target.checked 
                    });
                  }}
                  inputProps={{
                    "aria-label": "Receive daily recap emails with all assessment completions",
                  }}
                />
              } 
            />
          </FormGroup>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            component="div"
          >
            Feature Flags
          </Typography>
          <FormGroup>
            <FormControlLabel 
              label="Allow developmental assessment lawyer overview"
              control={
                <Checkbox
                  disabled={true}
                  checked={newSettings.coachFeatureEnabled == true}
                  onChange={(event) => {
                    setNewSettings({ 
                      ...newSettings, 
                      coachFeatureEnabled: event.target.checked 
                    });
                  }}
                  inputProps={{
                    "aria-label": "Allow developmental assessment lawyer overview",
                  }}
                />
              } 
            />
          </FormGroup>
        </>
      )}
    </Box>
  );
};

export default connect(null, { fetchFirmSession })(ClientSettings)
