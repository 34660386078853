import React, { Component } from "react";
import axios from "axios";
import moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, Box } from "@mui/material";
import SurveyInviteModal from "./SurveyInviteModal";


class SurveyData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInviteModal: false,
      clients: [],
      loaded: false,
      indicators: [],
      stats: null,
      pending: [],
    };

    this.getSurveySummaries = this.getSurveySummaries.bind(this);
  }

  getSurveySummaries() {
    axios({
      method: "get",
      url: `/api/survey/admin/${this.props.location.state.clientId}`,
      responseType: "json",
    })
      .then((response) => {
        this.setState({
          ...this.state,
          loaded: true,
          indicators: response.data.indicators,
          stats: response.data.stats,
          pending: response.data.pending,
        });
      })
      .catch((e) => console.log(e));
  }

  showInviteModal = (show) => {
    this.setState({ showInviteModal: show || !this.state.showInviteModal });
  };

  componentDidMount() {
    this.getSurveySummaries();
  }
  render() {
    let indicatorSummaries = this.state.indicators.map((idx, i) => {
      return (
        <TableRow key={i + 1}>
          <TableCell>{idx.indicator_name}</TableCell>
          <TableCell>{idx.indicator_sum}</TableCell>
        </TableRow>
      );
    });

    let pendingInvites = this.state.pending.map((inv, i) => {
      return (
        <TableRow key={i + 1}>
          <TableCell>{inv.email}</TableCell>
          <TableCell>{moment(inv.created_at).format("MM/DD/YYYY")}</TableCell>
          <TableCell>
            <Typography
              sx={{
                backgroundColor: "#c0c0c0",
                padding: "6px",
                color: "#555555",
                fontSize: "8pt",
                fontFamily: "consolas, sans-serif",
              }}
            >
              https://portal.thine.co/survey/{inv.code}
            </Typography>
          </TableCell>
          <TableCell>
            <IconButton
              size="small"
              onClick={() =>
                navigator.clipboard.writeText(
                  "https://portal.thine.co/survey/" + inv.code
                )
              }
            >
              <ContentCopyIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });

    return (
      <>
        {this.state.loaded ? (
          <>
            {this.state.showInviteModal ? (
              <SurveyInviteModal
                clientId={this.props.location.state.clientId}
                close={this.showInviteModal}
              />
            ) : null}

            <Typography variant="h5">
              Survey Results | {this.props.location.state.clientName}
            </Typography>

            <Table
              component={Paper}
              size="small"
              sx={{ maxWidth: 900, margin: 1 }}
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#5d2a5f" }}>
                  <TableCell>
                    <Typography
                      variant="button"
                      sx={{ fontWeight: "bold", color: "#ffffff" }}
                    >
                      Total Invitations
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="button"
                      sx={{ fontWeight: "bold", color: "#ffffff" }}
                    >
                      Surveys Completed
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="button"
                      sx={{ fontWeight: "bold", color: "#ffffff" }}
                    >
                      Surveys Remaining
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{this.state.stats.total_invited}</TableCell>
                  <TableCell>{this.state.stats.complete_ct}</TableCell>
                  <TableCell>{this.state.stats.invited_ct}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div>
              <Typography variant="h6">Survey Results to Date</Typography>
              <Table
                component={Paper}
                size="small"
                sx={{ maxWidth: 900, margin: 1 }}
              >
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#5d2a5f" }}>
                    <TableCell>
                      <Typography
                        variant="button"
                        sx={{ fontWeight: "bold", color: "#ffffff" }}
                      >
                        Indicator Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="button"
                        sx={{ fontWeight: "bold", color: "#ffffff" }}
                      >
                        Consensus Count
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{indicatorSummaries}</TableBody>
              </Table>
            </div>

            <Box
              sx={{ maxWidth: 900, margin: 1 }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              m={1}
            >
              <Typography variant="h6" sx={{ margin: 1 }}>
                Pending Invitations
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  this.showInviteModal(true);
                }}
              >
                Invite
              </Button>
            </Box>

            <Table component={Paper} sx={{ maxWidth: 900, margin: 1 }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#5d2a5f" }}>
                  <TableCell>
                    <Typography
                      variant="button"
                      sx={{ fontWeight: "bold", color: "#ffffff" }}
                    >
                      Email Address
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="button"
                      sx={{ fontWeight: "bold", color: "#ffffff" }}
                    >
                      Date Invited
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="button"
                      sx={{ fontWeight: "bold", color: "#ffffff" }}
                    >
                      Survey Link
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{pendingInvites}</TableBody>
            </Table>
          </>
        ) : null}
      </>
    );
  }
}

export default SurveyData;
