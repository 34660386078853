import React, { Component } from "react";
import { Link } from "react-router-dom";
import request from "../RequestUtil.js";
import moment from "moment";
import qs from "qs";

// icon imports
import { FiBookOpen } from "react-icons/fi";

class MyAssessments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assessments: [],
    };
  }

  componentDidMount() {
    request({
      method: "get",
      url: "/api/assessments/user/assigned",
      responseType: "json",
    })
      .then((response) => {
        this.setState({ assessments: response.data });
      })
      .catch((err) => console.log(err));
  }
  render() {
    let list = this.state.assessments.map((item, i, arr) => {
      item.assign_human = moment(item.assign_dt).format("M/DD/YYYY");
      return <AssessmentListItem key={i} item={item} />;
    });
    return (
      <div className="card">
        <h2>Assigned to me</h2>
        <table>
          <thead>
            <tr>
              <th>Assessment Title</th>
              <th>Assigned on</th>
              <th className="mobile-hide">Description</th>
              <th className="mobile-hide">Assigned by</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{list}</tbody>
        </table>
      </div>
    );
  }
}

class AssessmentListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const isGeneral = this.props.item.is_general || false;
    const assessmentQuery = qs.stringify(
      {
        id: this.props.item.assignment_id,
        desc: this.props.item.assessment_description,
        title: this.props.item.assessment_title,
        type: this.props.item.assessment_type,
        is_general: isGeneral,
      },
      { addQueryPrefix: true }
    );

    return (
      <tr>
        <td>{this.props.item.assessment_title}</td>
        <td>{this.props.item.assign_human}</td>
        <td className="mobile-hide">
          {this.props.item.assessment_description}
        </td>
        <td className="mobile-hide">
          {isGeneral ? "Thine" : this.props.item.assigned_by}
        </td>
        <td>
          <Link
            to={{
              pathname: "/take",
              state: this.props.item,
              search: assessmentQuery,
            }}
          >
            <button className="button center-me">
              Take
              <FiBookOpen className="svg-icon" title="Take" size="1em" />
            </button>
          </Link>
        </td>
      </tr>
    );
  }
}

export default MyAssessments;
