import axios from "axios";

// Todo - move this to a common place later
axios.defaults.withCredentials = true;

export const getClientCandidateAssissmentMapping = (id) => {
  return axios.get(`/api/client-candidate-assessment-mapping?id=${id}`, { withCredentials: true })
    .then(response => {
        return response.data;
    });
}

export async function updateClientCandidateAssissmentMapping(id, data = {}) {
  const response = await fetch(`/api/client-candidate-assessment-mapping/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response;
}
