import ErrorWrapper from "./ErrorWrapper";
import Image from "../../images/403.svg";

const Error403 = () => {
  return (
    <ErrorWrapper>
      <div className="content">
        <div className="content-inner">
          <h1>403</h1>
          <h2>Access Denied</h2>
          <p>
            We are sorry, but you do not have access to the page you are trying
            to visit. Please try navigating back to the previous page, or
            getting in touch at{" "}
            <a href="mailto:helpdesk@thine.co">helpdesk@thine.co</a>.
          </p>
        </div>
      </div>
      <div className="image">
        <img src={Image} alt="Illustration for unauthorized route error" />
      </div>
    </ErrorWrapper>
  );
};

export default Error403;
