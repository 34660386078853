import _ from "lodash";
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import Paginator from "../Components/Paginator";
import request from "../RequestUtil.js";
import CandidateSteps from "../Components/CandidateSteps";
import Demographics from "./Demographics";
import DevelopmentalDemographics from "./DevelopmentalDemographics";
import QstSlider from "../Components/QstSlider";
import ScaleButtons from "../Components/ScaleButtons";
import OCIInstructions from "../Components/OciInstructions";
import { DragAndDropQuestion } from "../Components/AssessmentTake/QuestionTypes/DragAndDrop";
import GeneralLevelAssessmentConsent from "../Components/GeneralLevelAssessmentConsent";
import qs from "qs";
import { assessmentValues, startPositionMap } from "../config/AppConst.js";
import BasicSnackbar from "../Components/Utilities/BasicSnackbar.jsx";
import { withRouter } from "react-router";
import Loader from "../Components/Loader";
import lodashGet from "lodash/get";


const PAGE_LEN = 30;

const getStartPosition = (assessmentType) => {
  if (startPositionMap.left.includes(assessmentType)) {
    return 0;
  } else if (startPositionMap.right.includes(assessmentType)) {
    return 4;
  } else {
    return 2;
  }
};

const getButtonBased = (assessmentType) => {
  return (
    assessmentValues.developmental.includes(assessmentType) ||
    assessmentValues.altOci.includes(assessmentType)
  );
};

class TakeAssessment extends Component {
  constructor(props) {
    super(props);
    this.assessmentQuery = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.assign_id = this.assessmentQuery.id;
    this.assessment_title = this.assessmentQuery.title;
    this.assessment_description = this.assessmentQuery.desc;
    this.assessment_type = this.assessmentQuery.type;
    this.isGeneral = _.get(this, "assessmentQuery.is_general", false) == "1";

    this.state = {
      stage: this.assessmentQuery.stage || 0,
      questions: [],
      currentPage: 0,
      currentPageAnswers: [],
      currents: [],
      anon: false,
      pageList: [],
      user: {},
      range: null,
      isLoaded: false,
      isError: false,
      paginationIndex: [],
      assignment_id: this.assign_id,
      assessment_title: this.assessment_title,
      assessment_description: this.assessment_description,
      sendMessageResponse: {
        open: false,
        message: "",
        severity: "success",
      },
      isSubmitting: false,
    };

    this.start = this.start.bind(this);

    this.getRange = this.getRange.bind(this);
    this.setPage = this.setPage.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
    this.complete = this.complete.bind(this);
    this.markAnon = this.markAnon.bind(this);

    this.updateCurrents = this.updateCurrents.bind(this);
    this.markAnswered = this.markAnswered.bind(this);
    this.lastQstNumber = 0;
  }

  beforeStart() {
    this.state.user.demostaken ?
    this.start() :
    this.setState({ stage: 0.5 });
  }

  start() {
    request({
      method: "post",
      url: "/api/assessments/begin",
      data: {
        assign_id: this.assign_id,
      },
      responseType: "json",
    }).then((response) => {
      this.setState({ stage: 1 });
    }).catch((err) => console.log(err));
  }

  markAnon(e) {
    this.setState({ anon: e.target.checked });
  }

  componentDidMount() {
    this.getRange(this.state.currentPage);
    fetch("/api/users/session", {
      headers: {
        accepts: "application/json",
      },
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ user: res });
      });
  }

  async getRange(page) {
    this.setState({ isLoaded: false });
    let { questions, paginationIndex } = this.state;
    if (questions && questions.length > 0) {
      if (page > paginationIndex.length - 1) {
        return;
      }
      let start = paginationIndex[page].start;
      let end = paginationIndex[page].end + 1;
      let pageList = questions.slice(start, end);
      this.setState({ pageList, isLoaded: true });
    } else {
      request({
        method: "post",
        url: "/api/assessments/take",
        data: { assignment_id: this.assign_id },
        responseType: "json",
      })
        .then((response) => {
          let {
            data: { assessmentType: assessment_type = 0, questions = [] } = {},
          } = response;
          if (questions.length > 0) {
            let qstNumber = 0;
            let questionsCount = questions.filter(q => q.question_type === 5).length; // TODO: only ranked choice?
            questions = questions.map((q, i) => {
              qstNumber = q.question_type === 0 ? qstNumber : qstNumber + 1;
              return { ...q, key: i, qstNumber, questionsCount };
            });

            let buildState = {
              // questions: response.data, // revert if bad
            };
            let lastQstNumber = -1;
            while (lastQstNumber < questions.length - 1) {
              const start = lastQstNumber + 1;
              let end =
                start + PAGE_LEN < questions.length
                  ? start + PAGE_LEN
                  : questions.length - 1;

              while (questions[end].question_type === 0) {
                end -= 1;
              }
              paginationIndex.push({ start, end });
              lastQstNumber = end;
            }
            let start = paginationIndex[0].start;
            let end = paginationIndex[0].end + 1;
            let pageList = questions.slice(start, end);
            this.setState({
              pageList,
              isLoaded: true,
              paginationIndex,
              questions,
              assessment_type,
            });
          }
        })
        .catch((err) =>
          this.setState({
            isLoaded: true,
            isError: true,
            sendMessageResponse: {
              ...this.state.sendMessageResponse,
              open: true,
              message: "Something went wrong!",
              snackbarOpen: true,
              severity: "error",
            },
          })
        );
    }
  }

  // update answered status in currents
  markAnswered(id) {
    // find in currents
    let newState = this.state;
    let idx = newState.currents.findIndex((el) => el.question == id);

    // set answered to true
  }

  setPage(page) {
    let { currentPage, questions, paginationIndex } = this.state;

    let items = document.getElementsByClassName("unanswered");
    if (items.length !== 0 && page > currentPage) {
      alert("Please answer all questions before advancing to the next page.");
    } else {
      if (page === paginationIndex.length) {
        this.complete();
      } else {
        this.setState({ currentPage: page }, () => this.getRange(page));
      }
    }
  }

  submitAnswer(item, callback = () => { }) {
    // api/assessments/user/assigned/take/answers
    let { question_id, answer_id, assign_id, question_type } = item;
    let doUpdate = true
    // if question type is not 3 (checklist), set update flag to TRUE. otherwise, set to FALSE for insert.
    if (question_type === 3) {
      doUpdate = false;
    }

    request({
      method: "post",
      url: "/api/assessments/user/assigned/take/answers",
      responseType: "json",
      data: {
        items: [item],
        doUpdate: doUpdate,
      }
    })
      .then((response) => {
        callback();
        this.markAnswered(question_id);
      })
      .catch((err) =>
        this.setState({
          sendMessageResponse: {
            ...this.state.sendMessageResponse,
            open: true,
            message: "Something went wrong!",
            snackbarOpen: true,
            severity: "error",
          },
        })
      );
  }

  complete() {
    this.setState({
      isSubmitting: true,
    }, () => {
      request({
        method: "post",
        responseType: "json",
        data: {
          assign_id: this.state.assignment_id,
          test: "test",
          anon: this.state.anon,
        },
        url: "/api/assessments/user/assigned/complete",
      })
        .then((response) => {
          // set stage to 4 and show a confirmation view
          if (!assessmentValues.entryLevel.includes(this.state.assessment_type)) {
            this.setState(
              {
                isSubmitting: false,
                sendMessageResponse: {
                  ...this.state.sendMessageResponse,
                  open: true,
                  message: "Assessment Submitted Successfully!",
                  snackbarOpen: true,
                  severity: "success",
                },
              },
              () =>
                setTimeout(() => {
                  this.props.history.push("/");
                }, 2000)
            );
          } else {
            this.setState({
              isSubmitting: false,
              stage: this.isGeneral ? 2.5 : 3,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isSubmitting: false,
            sendMessageResponse: {
              ...this.state.sendMessageResponse,
              open: true,
              message: _.get(
                err, 
                "response.data.error", 
                "Something went wrong! Please try again later."
              ),
              snackbarOpen: true,
              severity: "error",
            },
          });
        });
    });
  }

  updateCurrents(arr) {
    let newState = this.state;
    newState.currents = arr;

    this.setState(newState);
  }

  handleCandidateConsent = async (agree) => {
    try {
      if (agree) {
        await request({
          method: "put",
          url: `/api/assessment-assign/${this.assign_id}`,
          data: { shareResults: 1 },
          responseType: "json",
        });
      }

      this.setState({ stage: 3 });
    } catch (error) {
      console.log("Error while saving consent: ", error);
    }
  };

  render() {
    let {
      assessment_type,
      pageList: temp,
      isLoaded,
      isError,
      currentPage,
      paginationIndex,
      isSubmitting,
    } = this.state;

    const startPosition = getStartPosition(assessment_type);
    const isButtonBased = getButtonBased(assessment_type);

    let currents = temp.map((q, i) => {
      if (q.question_type != 4) {
        return {
          answered: false,
          question_id: q.question_id,
        };
      }
    });
    let currentDisplay = temp.map((item, i, arr) => {
      return (
        <li key={item.key}>
          {item.question_type === 0 && (<Meta question={item} />)}
          {item.question_type === 1 && (
            <MultipleChoice
              question={item}
              submitAnswer={this.submitAnswer}
              assign_id={this.state.assignment_id}
              assessment_type={assessment_type}
              qstNumber={item.qstNumber}
            />
          )}
          {(item.question_type === 2 || item.question_type == 4) && (
            <Slider
              question={item}
              submitAnswer={this.submitAnswer}
              assign_id={this.state.assignment_id}
              qstNumber={item.qstNumber}
              startPosition={startPosition}
              assessmentType={assessment_type}
              isButtonBased={isButtonBased}
            />
          )}
          {item.question_type === 3 && (
            <Checklist
              question={item}
              submitAnswer={this.submitAnswer}
              assign_id={this.state.assignment_id}
              qstNumber={item.qstNumber}
            />
          )}
          {item.question_type === 5 && (
            <DragAndDropQuestion
              question={item}
              submitAnswer={this.submitAnswer}
              assign_id={this.state.assignment_id}
              qstNumber={item.qstNumber}
            />
          )}
        </li>
      );
    });

    let candidateStep = 0;
    switch (this.state.stage) {
      case 0:
        candidateStep = 0;
        break;
      case 0.5:
        candidateStep = 1;
        break;
      case 1:
      case 2:
        candidateStep = 2;
        break;
      case 2.5:
        candidateStep = 3;
        break;
      case 3:
        candidateStep = 4;
        break;
      default:
    }

    const isEntry = assessmentValues.entryLevel.includes(assessment_type);
    const steps = ["Instructions", "Demographics", "Assessment"];

    if (isEntry) {
      if (this.isGeneral) {
        steps.push("Consent");
      }
      steps.push("Results");
    }

    return (
      <section>
        {isLoaded && !isError ? (
          <div className="assessment-wrapper">
            <CandidateSteps steps={steps} step={candidateStep} />
            <h1
              id="region-heading"
              style={{
                fontWeight: 300,
                fontSize: "40px",
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              {this.state.assessment_title}
            </h1>
            {this.state.stage === 0 ? (
              <OCIInstructions
                onClick={this.beforeStart.bind(this)}
                assessmentType={assessment_type}
              />
            ) : null}
            {this.state.stage === 0.5 ? (
              assessmentValues.developmental.includes(assessment_type) ? (
                <DevelopmentalDemographics onFinish={this.start} />
              ) : (
                <Demographics onFinish={this.start} />
              )
            ) : null}
            {this.state.stage === 1 ? (
              <div>
                <ul>{currentDisplay}</ul>

                <Paginator
                  isSubmitting={isSubmitting}
                  pageLimit={paginationIndex.length}
                  items={this.state.questions.length}
                  pageSize={PAGE_LEN}
                  pageNeighbors={2}
                  onChangePage={this.setPage}
                  currentPage={this.state.currentPage}
                  nextText={this.state.currentPage + 1 < paginationIndex.length}
                />
              </div>
            ) : null}

            {/*
              [Pushkar] - I dont think stage 2 is used now, we should remove this
            */}
            {this.state.stage === 2 ? (
              <div className="card">
                <p>
                  You've reached the end of the assessment. Select "Submit"
                  below to mark it as complete, or "Back" to review your
                  choices.
                </p>
                <button className="button" onClick={this.complete}>
                  Submit
                </button>
                <button className="button" onClick={this.start}>
                  Back
                </button>
              </div>
            ) : null}
            {this.state.stage === 2.5 ? (
              <GeneralLevelAssessmentConsent
                handleDecline={() => this.handleCandidateConsent(false)}
                handleSubmit={() => this.handleCandidateConsent(true)}
              />
            ) : null}
            {this.state.stage === 3 && isEntry ? (
              <Redirect
                to={`/candidate/results?assignment_id=${this.assign_id}`}
              />
            ) : null}
          </div>
        ) : null}
        <BasicSnackbar
          snackbarOpen={this.state.sendMessageResponse.open}
          setSnackbarOpen={(value) =>
            this.setState({
              sendMessageResponse: {
                ...this.state.sendMessageResponse,
                open: value,
              },
            })
          }
          message={this.state.sendMessageResponse.message}
          severity={this.state.sendMessageResponse.severity}
        />
        {!isLoaded && <Loader />}
      </section>
    );
  }
}

// slider question
class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answered: false,
      answer: null,
      index: this.props.startPosition, // start at 1 for developmental, else start at 3
      checkedExisting: false,
    };

    this.handleSlide = this.handleSlide.bind(this);
  }

  componentDidMount() {
    let update_req = {
      assign_id: this.props.assign_id,
      question_id: this.props.question.question_id,
    };
    request({
      method: "post",
      url: "/api/assessments/user/assigned/take/answered",
      responseType: "json",
      data: update_req,
    })
      .then((response) => {
        if (response.data.length > 0) {
          let { answer_id } = response.data[0];

          let index = this.props.question.answers.findIndex(
            (answer) => answer.answer_id === answer_id
          );

          this.setState({
            answered: true,
            answer: answer_id,
            index: index,
            checkedExisting: true,
          });
        } else {
          this.setState({ answered: false, checkedExisting: true });
        }
      })
      .catch((err) => console.log(err));
  }

  handleSlide = (value) => {
    console.log("Inside handleSlider", value)
    let selected = this.props.question.answers[value].answer_id;
    let item = {
      question_id: this.props.question.question_id,
      answer_id: selected,
      assign_id: this.props.assign_id,
      question_type: 2,
    };
    this.props.submitAnswer(item);
    this.setState({ answered: true });
  };

  render() {
    const slideType = [1, 2].includes(this.props.question.section_num)
      ? "button"
      : "slide";
    return (
      <div className="qst">
        <h3
          className="qst-title"
          style={{
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            fontSize: "15pt!important",
          }}
        >
          {this.props.question.question_title}
        </h3>
        <div className="qst-inner">
          <p>{this.props.question.question_description}</p>
        </div>
        {this.props.isButtonBased && slideType == "button" ? (
          <ScaleButtons
            section={this.props.question.section_num}
            answered={this.state.answered}
            answeredIndex={this.state.index}
            question={this.props.question}
            handleSlide={this.handleSlide}
          ></ScaleButtons>
        ) : (
          <QstSlider
            className={this.state.answered === false ? "unanswered" : ""}
            min="1"
            max="5"
            section={this.props.question.section_num}
            defaultValue={this.state.index + 1}
            answeredIndex={this.state.index}
            startPosition={this.props.startPosition}
            answered={this.state.answered}
            name={"question-" + this.props.question.question_id}
            right={this.props.question.label_right}
            left={this.props.question.label_left}
            accessibilityTitle={this.props.question.question_title}
            handleSlide={this.handleSlide}
            width={Math.min(document.body.offsetWidth - 80, 500)}
          />
        )}
      </div>
    );
  }
}

// multiple choice question
class MultipleChoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answered: false,
      answer: null,
    };
  }

  componentDidMount() {
    let update_req = {
      assign_id: this.props.assign_id,
      question_id: this.props.question.question_id,
    };
    request({
      method: "post",
      url: "/api/assessments/user/assigned/take/answered",
      responseType: "json",
      data: update_req,
    })
      .then((response) => {
        if (response.data.length > 0) {
          let { answer_id } = response.data[0];

          this.setState({
            answered: true,
            answer: answer_id,
          });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { assessment_type, qstNumber } = this.props;
    const style = {
      display: "inline-flex",
      with: "30%",
    };
    const containerStyle = {
      marginBottom: 0,
    };
    let options = this.props.question.answers.map((ans, i, arr) => {
      let item = {
        question_id: ans.question_id,
        answer_id: ans.answer_id,
        assign_id: this.props.assign_id,
        question_type: 1,
        mark: ans.answer_id === this.state.answer,
      };

      return (
        <>
          <div
            className="qst-inner"
            style={
              assessmentValues.integration.includes(assessment_type)
                ? style
                : {}
            }
            key={i}
          >
            <input
              type="radio"
              checked={item.mark}
              id={"answer-" + ans.answer_id}
              name={"question-" + ans.question_id}
              value={ans.answer_id}
              onClick={(e) => {
                this.props.submitAnswer(item, () => {
                  this.setState({
                    answer: ans.answer_id,
                  });
                });
              }}
              key={ans.answer_title + ans.answer_id}
            ></input>
            <label htmlFor={"answer-" + ans.answer_id}>
              {`${ans.answer_title}`}
            </label>
          </div>
          {i < arr.length - 1 &&
            !assessmentValues.integration.includes(assessment_type) ? (
            <br />
          ) : null}
        </>
      );
    });
    return (
      <Fragment>
        <div
          className="qst"
          style={
            assessmentValues.integration.includes(assessment_type)
              ? containerStyle
              : {}
          }
        >
          <fieldset id={"question-" + this.props.question.question_id}>
            {this.props.question.question_title ? (
              <>
                <h3
                  className={`qst-title ${this.state.answer ? "" : "unanswered"
                    }`}
                  style={{
                    fontFamily: "'Roboto', sans-serif",
                    fontWeight: 300,
                    fontSize: "14pt!important",
                    textAlign: "left",
                    marginLeft: "10px",
                  }}
                >
                  {/* {qstNumber + ". "} */}
                  {this.props.question.question_title}
                </h3>
                <br />
              </>
            ) : null}
            {this.props.question.question_description ? (
              <div className="qst-inner">
                <p>{this.props.question.question_description}</p>
                <br />
              </div>
            ) : null}
            <div className="qst-options">{options}</div>
          </fieldset>
        </div>
      </Fragment>
    );
  }
}

// checklist question
class Checklist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: this.props.question.answers,
    };

    this.checkCheck = this.checkCheck.bind(this);
  }

  componentDidMount() {
    let update_req = {
      assign_id: this.props.assign_id,
      question_id: this.props.question.question_id,
    };
    request({
      method: "post",
      url: "/api/assessments/user/assigned/take/answered",
      responseType: "json",
      data: update_req,
    })
      .then((response) => {
        if (response.data.length > 0) {
          let { answer_id } = response.data[0];

          let ids = response.data.map((item, i, arr) => {
            return item.answer_id;
          });

          let answersMatched = this.props.question.answers.map(
            (ans, i, arr) => {
              ans.checked = ids.includes(ans.answer_id);
              return ans;
            }
          );
          this.setState({ answers: answersMatched });
        }
      })
      .catch((err) => console.log(err));
  }

  checkCheck(e, item) {
    if (e.target.checked === true) {
      // add answer to responses table
      this.props.submitAnswer(item);
    } else {
      // remove answer from responses table
      // send assign id, question id, answer id
      request({
        method: "delete",
        url: "/api/assessments/user/assigned/take/answer",
        data: {
          question_id: item.question_id,
          answer_id: item.answer_id,
          assign_id: item.assign_id,
        },
        responseType: "json",
      })
        .then((response) => {
          this.forceUpdate();
        })
        .catch((err) => console.log(err));
    }
  }

  render() {
    const answers = lodashGet(this, "props.question.answers", []) || [];
    const items = answers.map((ans, i, arr) => {
      let detail = {
        assign_id: this.props.assign_id,
        question_id: ans.question_id,
        answer_id: ans.answer_id,
        question_type: 3,
      };
      return (
        <div className="qst-inner" key={i}>
          <input
            name={"answer-" + ans.answer_id}
            id={"answer-" + ans.answer_id}
            onClick={(e) => this.checkCheck(e, detail)}
            type="checkbox"
            defaultChecked={ans.checked}
            key={ans.answer_id}
          ></input>
          <label htmlFor={"answer-" + ans.answer_id}>
            <span>{ans.answer_title}</span>
          </label>
        </div>
      );
    });

    return (
      <Fragment>
        <div className="qst">
          <h3
            className="qst-title"
            style={{
              fontFamily: "'Roboto', sans-serif",
              fontWeight: 300,
              fontSize: "14pt!important",
              textAlign: "left",
            }}
          >
            {/* {this.props.qstNumber + ". "} */}
            {this.props.question.question_title}
          </h3>
          <div className="qst-inner">
            <p>{this.props.question.question_description}</p>
          </div>
          <fieldset
            id={"question-" + this.props.question.question_id}
            className="checkboxContainer"
          >
            {items}
          </fieldset>
        </div>
      </Fragment>
    );
  }
}

class Meta extends Component {
  render() {
    return (
      <Fragment>
        <div className="qst">
          <h2 style={{ padding: 0 }}>{this.props.question.question_title}</h2>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(TakeAssessment);
