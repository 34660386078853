import { Button, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StyledMenu from "./StyledMenu";

const CandidateStatusMenu = ({ onChange, status, menuItemsMap }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [chosen, setChosen] = useState(status);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value) => {
    setAnchorEl(null);
    if (value) {
      setChosen(value);
      onChange(value);
    }
  };

  useEffect(() => {
    setChosen(status);
  }, [status]); // Only re-run the effect if status changes

  return (
    <div>
      <Button
        sx={{ textTransform: "inherit", color: "black" }}
        id="table-customized-button"
        aria-controls="table-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon sx={{ color: "#45aae1" }} />}
      >
        {menuItemsMap[chosen]
          ? menuItemsMap[chosen].charAt(0).toUpperCase() +
            menuItemsMap[chosen].slice(1)
          : "-"}
      </Button>
      <StyledMenu
        id="table-customized-menu"
        MenuListProps={{
          "aria-labelledby": "table-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
      >
        {Object.keys(menuItemsMap).map((key) => (
          <MenuItem 
            key={key}
            onClick={() => handleClose(key)} disableRipple>
            {menuItemsMap[key]}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default CandidateStatusMenu;
