export const assessmentValues = {
  entryLevel: [1],
  lateral: [2, 3, 4],
  integration: [5, 6, 7, 8, 9],
  integrationNonCapital: [5, 6, 8, 9],
  capital: [7],
  developmental: [10],
  altOci: [11]
};

export const hiringStatusLabelMap = {
  invited: "-",
  interviewNotGranted: "Interview Not Granted",
  screened: "Screened",
  screenedRejected: "Screen Rejected",
  callback: "Called Back",
  callbackDeclined: "Callback Declined",
  offerNotExtended: "Offer Not Extended",
  offerExtended: "Offer Extended",
  offerAccepted: "Offer Accepted",
  offerDeclined: "Offer Declined",
};

export const candidateSourceLabelMap = {
  Recruiter: "Recruiter",
  Referral: "Referral",
  Linkedin: "Linkedin",
  "Job Posting": "Job Posting",
  "Direct Outreach": "Direct Outreach",
  Other: "Other",
};

export const priorExperienceLabelMap = {
  "Law Firm": "Law Firm",
  "In-House": "In-House",
  Clerkship: "Clerkship",
  Other: "Other",
};

export const startPositionMap = {
  left: [], // leaving the logic in case we need it later but removing developmental type id from here
  right: []
}
