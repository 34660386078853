import {
  Divider,
  Paper,
  styled,
  Typography,
  Link as MuiLink,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";

export default function GeneralLevelAssessmentConsent({
  handleDecline,
  handleSubmit,
}) {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    lineHeight: "60px",
  }));

  const titleContainerStyles = {
    width: "100%",
    backgroundColor: "#ecf6fc",
    paddingTop: 2,
    paddingBottom: 1,
    paddingLeft: 5,
    paddingRight: 5,
  };

  const titleStyles = {
    color: "#53585c",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "bold",
    textAlign: "left",
  };

  const bodyStyles = {
    textAlign: "left",
    maxWidth: 900,
  };

  const bodyContainerStyles = {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 5,
  };

  return (
    <Item elevation={2} variant="elevation">
      <Box sx={titleContainerStyles}>
        <Typography
          sx={titleStyles}
          variant="subtitle1"
          gutterBottom
          component="div"
        >
          Thank you for completing Thine's assessment!
        </Typography>
      </Box>
      <Box sx={bodyContainerStyles}>
        <Typography sx={bodyStyles} variant="body1" gutterBottom>
          Our mission at Thine is to help candidates be true to themselves, and
          find the firm where they will be most successfully aligned. If you'd
          like to authorize all of our clients the ability to access your
          assessment results, click accept below. If you would prefer to not
          share your results with all of our participating clients, please
          select decline below. Please note that granting access to any of these
          firms to access your results does not guarantee that they will opt to
          view them.
        </Typography>
      </Box>
      {/* <Box sx={bodyContainerStyles}>
        <Typography sx={bodyStyles} variant="body1" gutterBottom>
          Our clients include Shearman & Sterling, Katten, Cooley, Holland &
          Hart, Wiley Rein, Hogan Lovells, Proskauer.
        </Typography>
      </Box> */}
      {/* <Divider></Divider> */}
      <Box
        sx={{
          width: "100%",
          display: "inline-flex",
          flexDirection: "row",
          justifyContent: "right",
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <Button
          variant="contained"
          sx={{ textTransform: "none", width: "160px" }}
          onClick={handleDecline}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: "8px" }}
          sx={{ textTransform: "none", width: "160px" }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Item>
  );
}
