import React from "react";
import {useQuery} from "react-query";

type Assessment = {
    "assessmentId": number;
    "assessmentTitle": string | null
    "assessmentDescription": string | null
    "assessmentScope": number | null
    "assessmentFor": number | null
    "createdDt": Date | null
    "createdBy": string | null
    "assessmentType": number,
    "assessmentWeighted": boolean
    "reportId": number
    "templateLvl": number
    "constId": number | null
    "candidateReportId": number
    "assessmentString": string
    "poolId": number | null
    "candidateSurveyLink": string | null
    "version": number
    "createdAt": Date | null
    "updatedAt": Date | null
}

function getAssessment(assessment_id: string) {
    return fetch(`/api/admin/debug/assessment/${assessment_id}`, {
        headers: {
            accepts: "application/json",
        },
        method: "get",
    })
        .then((res) => res.json() as Promise<{ assessment: Assessment }>)
}


export function DebugAssessment({match: {params: {assessment_id}}}: any) {
    const query = useQuery({queryKey: ['assessment', assessment_id], queryFn: () => getAssessment(assessment_id)})


    return (
        <div>
            <p>{assessment_id}</p>
            <pre>{JSON.stringify(query, null, 2)}</pre>
        </div>

    )
}

