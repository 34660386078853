import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import "../../../css/fontawesome-v6/css/all.css";

const SurveyCheckBox = ({
  checked,
  label,
  subtitle,
  icon,
  onChange,
  sx,
  meta,
}) => {
  const { junior_description, mid_level_description, senior_description } =
    meta;
  return (
    <Box
      sx={{
        ...sx,
        display: "inline-flex",
        flexDirection: "row",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Checkbox checked={checked} onChange={onChange} />
        <span
          className={"fa-solid " + icon}
          style={{ marginRight: "10px", marginTop: "10px" }}
        ></span>
      </Typography>
      <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 800, mb: 2, mt: 1 }}>
          {label}
        </Typography>
        <Typography variant="subtitle2" sx={{ textAlign: "justify" }}>
          {subtitle}
        </Typography>

        {meta.junior_description && (
          <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 600, mb: 1, mt: 1 }}
            >
              Junior Level
            </Typography>
            {junior_description.map((label) => (
              <Typography variant="subtitle2" sx={{ textAlign: "justify" }}>
                {label}
              </Typography>
            ))}
          </Box>
        )}
        {/* {mid_level_description && <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 ,mt: 1}}>
            Mid Level
          </Typography>
            {mid_level_description.map((label)=>
              <Typography variant="subtitle2" sx={{ textAlign: "justify" }}>
                {label}
              </Typography>
              )} 
      </Box>}
      {senior_description && <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1, mt: 1 }}>
            Senior Level
          </Typography>
            {senior_description.map((label)=>
              <Typography variant="subtitle2" sx={{ textAlign: "justify" }}>
                {label}
              </Typography>
              )} 
      </Box>}
       */}
      </Box>
    </Box>
  );
};

export default SurveyCheckBox;
