import React, { useEffect, useRef, useCallback } from "react";
import _ from "lodash";

const IDLE_TIMEOUT = 1000 * 60 * 60 * 2; // 2 hours

const IdleTracker = (props) => {
  const { baseUrl } = props;
  /*
   * Track active browser activity and log out the user if they are idle past the limit.
   * This is a wrapper component for <Main> and will not render anywhere else (survey, etc)
   */

  const timerRef = useRef(null);

  const logOut = () => {
    window.location.href = `${baseUrl}/api/logout?returnTo=${baseUrl}/session-timeout.html`;
  };

  const initTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      logOut();
    }, IDLE_TIMEOUT);

    
  };

  // debounce the timer reset because there are too many events firing in rapid succession otherwise during normal use
  const resetTimer = useCallback(_.debounce(initTimer, 5000));

  useEffect(() => {
    
    initTimer();
    const trackedEvents = [
      "mousedown",
      "keydown",
      "scroll",
      "touchstart",
    ];

    // duplicates are discarded and do not need to be removed
    trackedEvents.forEach((eventName) => {
      document.addEventListener(eventName, resetTimer, false);
    });

    return () => clearTimeout(timerRef.current);
  }, []);

  return <>{props.children}</>;
};

export default IdleTracker;
