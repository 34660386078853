import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import { useHistory } from "react-router-dom";

const BackButton = (props) => {
  let history = useHistory();
  const mb = props.mb || 0;
  return (
    <Button
      variant="outlined"
      sx={{ height: "38px", mb: mb }}
      onClick={() => history.goBack()}
    >
      <ArrowBackIcon></ArrowBackIcon> Back
    </Button>
  );
};

export default BackButton;
