import React, { Component } from "react";
import request from "../RequestUtil.js";
import { Link } from "react-router-dom";
import BasicSnackbar from "../Components/Utilities/BasicSnackbar.jsx";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      name: {
        first: null,
        last: null,
      },
      tagline: null,
      bio: null,
      avatar_url: null,
      school: null,
      gpa: null,
      firmName: null,
      firmRole: null,
    };
  }

  componentDidMount() {
    request({
      method: "post",
      url: "/api/users/profile/check",
      responseType: "json",
    })
      .then((response) => {
        let state = this.state;
        state.name.first = response.data.first_name;
        state.name.last = response.data.last_name;
        state.firmRole = response.data.role_name;
        state.avatar_url = response.data.avatar_url;
        state.tagline = response.data.tagline;
        state.bio = response.data.bio;
        state.school = response.data.school;
        state.gpa = response.data.gpa;
        state.loaded = true;

        this.setState(state);
      })
      .catch((err) => console.log(err));
  }

  isFirm() {
    return (
      this.props.user.perms.includes(1) ||
      this.props.user.perms.includes(4) ||
      this.props.user.perms.includes(5) ||
      this.props.user.perms.includes(7)
    );
  }

  render() {
    const firm = this.isFirm();
    const { firmName, firmRole } = this.state;
    return (
      <div>
        <div>
          <h1>Profile</h1>
          {this.state.loaded ? (
            <UpdateForm
              first_name={this.state.name.first}
              last_name={this.state.name.last}
              tagline={this.state.tagline}
              bio={this.state.bio}
              school={this.state.school}
              gpa={this.state.gpa}
              firmName={firmName}
              firmRole={firmRole}
              isFirm={firm}
              setProfile={this.props.setProfile}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

class UpdateForm extends Component {
  constructor(props) {
    super(props);
    const { firmName = null, firmRole = null } = this.props;
    this.state = {
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      tagline: this.props.tagline || null,
      bio: this.props.bio || null,
      school: this.props.school || null,
      gpa: this.props.gpa || null,
      firmName,
      firmRole,
      success: false,
      error: "",
      showAlert: "",
    };

    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    // this.handleTaglineChange = this.handleTaglineChange.bind(this);
    // this.handleBioChange = this.handleBioChange.bind(this);
    this.handleSchoolChange = this.handleSchoolChange.bind(this);
    this.handleGpaChange = this.handleGpaChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFirstNameChange(e) {
    this.setState({ first_name: e.target.value, success: false });
  }

  handleLastNameChange(e) {
    this.setState({ last_name: e.target.value, success: false });
  }

  handleSchoolChange(e) {
    this.setState({ school: e.target.value, success: false });
  }

  handleGpaChange(e) {
    this.setState({ gpa: e.target.value, success: false });
  }

  handleFirmNameChange = (e) => {
    this.setState({ firmName: e.target.value, success: false });
  };

  handleFirmRoleChange = (e) => {
    this.setState({ firmRole: e.target.value, success: false });
  };

  handleSubmit() {
    request({
      method: "post",
      url: "/api/users/profile/update",
      responseType: "json",
      data: this.state,
    })
      .then((response) => {
        this.setState({ showAlert: "success" });
        this.props.setProfile && this.props.setProfile();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ showAlert: "error", error: "Something went Wrong " });
      });
  }

  render() {
    const { error, showAlert } = this.state;
    return (
      <div className="card">
        <div className="form">
          <div className="element">
            <input
              type="text"
              name="first_name"
              placeholder={this.props.first_name || "First Name"}
              onChange={this.handleFirstNameChange}
            />
          </div>

          <div className="element">
            <input
              type="text"
              name="last_name"
              placeholder={this.props.last_name || "Last Name"}
              onChange={this.handleLastNameChange}
            />
          </div>

          {!this.props.isFirm ? (
            <div className="element">
              <input
                type="text"
                name="last_name"
                placeholder={this.props.school || "Law School"}
                onChange={this.handleSchoolChange}
              />
            </div>
          ) : null
          // <div className="element">
          //   <input
          //     type="text"
          //     name="last_name"
          //     placeholder={this.props.firmName || "Law Firm"}
          //     onChange={this.handleFirmNameChange}
          //   />
          // </div>
          }

          {!this.props.isFirm ? (
            <div className="element">
              <input
                type="text"
                name="last_name"
                placeholder={this.props.gpa || "GPA"}
                onChange={this.handleGpaChange}
              />
            </div>
          ) : (
            <div className="element">
              <select onChange={this.handleFirmRoleChange}>
                <option value="0">Select Role</option>
                {["Admin", "Recruiter"].map((role, i) => (
                  <option
                    value={role}
                    key={i}
                    selected={this.props.firmRole === role}
                  >
                    {role}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* <div className="element">
          
          <input
            type="text"
            name="tagline"
            placeholder={this.props.tagline || 'Tagline'}
            onChange={this.handleTaglineChange}
          />
        </div>

        <div className="element">
          
          <textarea
            name="bio"
            placeholder={this.props.bio || 'A short biography'}
            onChange={this.handleBioChange}
          />
        </div> */}
          <BasicSnackbar
            message={
              showAlert === "success" ? "Profile Updated Successfully !" : error
            }
            snackbarOpen={showAlert}
            setSnackbarOpen={() => this.setState({ showAlert: "" })}
            severity={showAlert}
          />
          <div className="element">
            <button
              className={
                this.state.success
                  ? "button form-button green"
                  : "button form-button"
              }
              onClick={this.handleSubmit}
            >
              {"Update"}
            </button>
            {/* <div>
                  {this.state.success == true ? (
                    <p>
                      <Link exact to={"/dash"}>
                        Return to the dashboard
                      </Link>
                    </p>
                  ) : null}
                </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
