import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Link, withRouter } from "react-router-dom";
import request from "../../RequestUtil.js";
import { parse } from "json2csv";
import { saveAs } from "file-saver";
import moment from "moment";
import "../../css/search.scss";
import SortableTable from "../../Components/SortableTable";
import Box from "@mui/material/Box";
import RectangleIcon from "@mui/icons-material/Rectangle";
import CircleIcon from "@mui/icons-material/Circle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import EditModal from "../../Components/EditModal";
import CandidateStatusMenu from "../../Components/CandidateStatusMenu";
import BasicSnackBar from "../../Components/Utilities/BasicSnackbar";
import TriangleIcon from "../../Components/TriangleIcon";
import SearchIcon from "@mui/icons-material/Search";
import BackButton from "../../Components/Utilities/BackButton";
import { Stack, TextField, Select } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CopyIcon from "@mui/icons-material/ContentCopy";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import {
  assessmentValues,
  hiringStatusLabelMap,
  candidateSourceLabelMap,
  priorExperienceLabelMap,
} from "../../config/AppConst.js";
import Loader from "../../Components/Loader";
import { getScoreMeta } from "../../snippets/utils";
import { getUsers } from "../../services/user";

const ITEM_HEIGHT = 48;
const CandidateScore = ({ score, scoreType }) => {
  const { color } = getScoreMeta(score);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {score ? (
        <>
          <RectangleIcon sx={{ color, transform: "scale(50%, 62.5%)" }} />
          {scoreType == "numeric" && (
            <span style={{ marginLeft: 2 }}>{score}</span>
          )}
        </>
      ) : (
        "NA"
      )}
    </Box>
  );
};

const CandidateUsage = ({ usage }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CandidateUsageIcon usage={usage} />
      <span style={{ marginLeft: 5 }}>
        <CandidateUsageText usage={usage} />
      </span>
    </Box>
  );
};

const CandidateUsageIcon = ({ usage }) => {
  switch (usage) {
    case "invited":
      return (
        <RectangleIcon
          sx={{
            color: "#45aae1",
            transform: "scaleY(40%)",
            width: 11.5,
          }}
        />
      );
    case "incomplete":
    case "pendingApproval":
      return <TriangleIcon color="#ab0700" direction="top" w="14" h="14" />;
    case "inReview":
      return <TriangleIcon color="#f5cb42" direction="top" w="14" h="14" />;
    case "completed":
      return (
        <CircleIcon
          sx={{
            color: "#008b57",
            transform: "scale(120%)",
            width: 10.5,
          }}
        />
      );
    default:
      return null;
  }
};

const CandidateUsageText = ({ usage }) => {
  switch (usage) {
    case "invited":
      return "Invited";
    case "incomplete":
      return "Incomplete";
    case "completed":
      return "Completed";
    case "pendingApproval":
      return "Pending Approval";
    case "inReview":
      return "In Review";
    default:
      return null;
  }
};

const getCandidateUsage = (
  {
    started_dt,
    completed_dt,
    is_manually_reviewed,
    status,
    consent_provided_at,
    score_value,
  },
  isEntryLevelJob
) => {
  if (completed_dt) {
    if (["pending", "inactive"].indexOf(status) > -1) {
      return "pendingApproval";
    } else if(isEntryLevelJob && score_value == null) {
      return "inReview";
    } 
    return "completed";
  } else if (started_dt) {
    return "incomplete";
  } else {
    return "invited";
  }
};

const TableMenu = ({
  id,
  job,
  jobId,
  candidateName,
  index,
  isIntegration,
  isLateral,
  isGeneralJob,
  setParentState,
  removeRow,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "29ch",
          },
        }}
      >
        {!isGeneralJob && (
          <MenuItem
            selected={false}
            onClick={() => {
              handleClose();
              setParentState({
                modalOpen: true,
                candidateName,
                candidateId: id,
                selectedIndex: index,
              });
            }}
          >
            <Typography variant="inherit" noWrap>
              Edit Candidate Information
            </Typography>
          </MenuItem>
        )}
        {
          props.usage == "completed" && job.isServerRenderedReport &&
          <MenuItem
            selected={false}
            onClick={() => {
              handleClose();
              window.open(`/firm/report/${id}`, "_blank");
            }}
          >
            <Typography variant="inherit" noWrap sx={{ color: "#000" }}>
              See Report
            </Typography>
          </MenuItem>
        }

        {/* CAUTION: We are removing see report for 2023 job searches */}
        {
          props.usage == "completed" && !job.isServerRenderedReport &&
          [148, 153, 165].indexOf(Number(jobId)) == -1 && (
            <Link
              to={{
                pathname: `/firm/report/${id}`,
              }}
            >
              <MenuItem
                selected={false}
                onClick={() => {
                  handleClose();
                }}
              >
                <Typography variant="inherit" noWrap sx={{ color: "#000" }}>
                  See Report
                </Typography>
              </MenuItem>
            </Link>
          )
        }
        {!isGeneralJob && (
          <MenuItem
            selected={false}
            onClick={async () => {
              handleClose();
              const response = await fetch(
                `/api/client-candidate-assessment-mapping/${id}/message`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ action: "reminding" }),
                }
              );

              if (response.status === 200) {
                props.setMessage("Reminder sent successfully!", "success");
              } else {
                props.setMessage("an error occurred sending invite", "error");
              }
            }}
          >
            <Typography variant="inherit" noWrap>
              Send Reminder
            </Typography>
          </MenuItem>
        )}
        {isGeneralJob && (
          <MenuItem
            selected={false}
            onClick={async () => {
              handleClose();
              const response = await fetch(
                `/api/client-candidate-assessment-mapping/${id}/move`,
                {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({}),
                }
              );

              if (response.status === 200) {
                props.setMessage(
                  "This candidate has been moved to your main job search!",
                  "success"
                );
                removeRow();
              } else {
                props.setMessage("Something went wrong!", "error");
              }
            }}
          >
            <Typography variant="inherit" noWrap>
              Move To Main
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

class ClientSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      viewType: 0,
      clientData: {},
      originalTableData: [],
      tableData: [],
      searchBy: "any",
      searchString: "",
      modalOpen: false,
      name: "",
      email: "",
      numSelected: 0,
      selectedItems: [],
      assessment_type: 0,
      candidateId: 0,
      sendMessageResponse: {
        open: false,
        message: "",
        severity: "success",
      },
      selectedIndex: 0,
      jobId: _.get(props, "match.params.jobId", -1),
      job: {},
      coachMap: {}, // This is mainly for development assessment
    };

    this.toCsv = this.toCsv.bind(this);
  }

  // TODO move these function to async-await for better readability
  async componentDidMount() {
    const { jobId } = this.state;

    // pull back records for that assessment
    request({
      method: "get",
      url: `/api/jobs/${jobId}/candidates`,
      responseType: "json",
    })
      .then((response) => {
        const data = _.get(response, "data", []);
        this.setState({
          isLoaded: true,
          tableData: data,
          originalTableData: data,
          assessment_type: _.get(response, "data[0].assessment_type", ""),
        });
      })
      .catch((err) => {
        this.setState({
          isLoaded: true,
          sendMessageResponse: {
            ...this.state.sendMessageResponse,
            open: true,
            message: "Something Went Wrong!",
            severity: "error",
          },
        });
      });

    request({
      method: "get",
      url: `/api/jobs?id=${jobId}`,
      responseType: "json",
    })
      .then((response) => {
        this.setState({
          job: _.get(response, "data.0", {}),
        });
      })
      .catch((err) => {
        console.log("error: ", err);
      });

    try {
      const users = await getUsers({
        isCoach: true,
        pageNumber: 0,
        pageSize: 2000,
      });

      this.setState({
        coachMap: users.rows.reduce((acc, user) => {
          acc[user.userId] = `${user.firstName} ${user.lastName}`;
          return acc;
        }, {}),
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }

  searchTable = (e) => {
    const text = _.toLower(e.target.value);

    let data = [...this.state.originalTableData].filter((row) => {
      switch (this.state.searchBy) {
        case "name":
          return _.toLower(row.candidate_name).includes(text);
          break;
        case "email":
          return _.toLower(row.email_addr).includes(text);
          break;
        case "any":
        default:
          return (
            _.toLower(row.candidate_name).includes(text) ||
            _.toLower(row.email_addr).includes(text)
          );
          break;
      }
    });
    this.setState({ searchString: text, tableData: data });
  };

  searchBy = (e) => {
    this.resetFilter();
    this.setState({ searchBy: e.target.value });
  };

  resetFilter = () => {
    this.setState({
      tableData: this.state.originalTableData,
      searchString: "",
    });
  };

  generateNorms = async (items) => {
    const { jobId } = this.state;
    let data = [];
    if (items && items.length > 0) {
      data = items.map((item) => this.state.tableData[item._ROWINDEX_]);
    } else {
      data = this.state.tableData;
    }

    const body = data
      .filter((row) => row.completed_dt)
      .map((row) => {
        return {
          id: row.id,
          assignmentId: row.assignment_id,
          jobId,
        };
      });

    const response = await fetch(`/api/scoring/norms`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const jsonResponse = await response.json();
    const something = window.open("data:text/json,", "_blank");
    something.document.write(`
      <div>${JSON.stringify(jsonResponse, null, "\t")}</div>
    `);
    something.focus();
  };

  toCsv(items) {
    const { scoreType } = this.props.firm;
    const { tableData, selectedItems } = this.state;
    const rowsToExport =
      selectedItems && selectedItems.length > 0 ? selectedItems : tableData;
    const exportData = rowsToExport.map((row, i) => {
      let scoreLabel = "";
      if (row.status == "active" && row.score_value != null) {
        scoreLabel =
          scoreType == "numeric"
            ? row.score_value
            : getScoreMeta(row.score_value).label;
      }

      return {
        candidate_name:
          row.client_candidate_name?.trim() ||
          row.candidate_name?.trim() ||
          "Not provided",
        email_addr: row.email_addr,
        school: row.school,
        invited: moment(row.assign_dt).format("MM/DD/YYYY"),
        started_timestamp: row.started_dt
          ? moment(row.started_dt).format("MM/DD/YYYY")
          : "-",
        completed_timestamp: row.completed_dt
          ? moment(row.completed_dt).format("MM/DD/YYYY")
          : "-",
        job_search: row.assessment_title,
        hiring_status: row.hiring_status,
        score: scoreLabel,
      };
    });

    // let data = [];
    // if (items && items.length > 0) {
    //   data = items.map((item) => exportData[item._ROWINDEX_]);
    // } else {
    //   data = exportData;
    // }

    // const csv = parse(data);

    const csv = parse(exportData);
    const filename = `Thine ${_.get(
      this.props,
      "location.state.jobName",
      ""
    )} ${moment(new Date()).format("MM.DD.YYYY,")}.csv`;

    const blob = new Blob([csv], { type: "text/plain;charset=utf=8" });
    saveAs(blob, filename);
  }

  getColumns = ({
    isIntegration,
    isLateral,
    isGeneralJob,
    isDevelopmental,
    isEntryLevelJob,
  }) => {
    const { scoreType, coachFeatureEnabled } = this.props.firm;
    const { jobId, job, coachMap } = this.state;
    const columns = [
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
        width: 120,
        isVisible: () => true,
        getValue: (row) =>
          row.client_candidate_name?.trim() ||
          row.candidate_name?.trim() ||
          "Not provided",
        render: (row) =>
          row.client_candidate_name?.trim() ||
          row.candidate_name?.trim() ||
          "Not provided",
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
        width: 250,
        isVisible: () => true,
        getValue: (row) => row.email_addr,
        render: (row) => row.email_addr,
      },
      {
        id: "university",
        numeric: false,
        disablePadding: false,
        label: "University",
        width: 250,
        isVisible: () => isGeneralJob,
        getValue: (row) => row.school,
        render: (row) => row.school,
      },
      {
        id: "staticSource",
        numeric: false,
        disablePadding: false,
        label: "Source",
        width: 80,
        isVisible: () => isEntryLevelJob,
        getValue: (row) => (row.is_general ? "Thine" : "Firm"),
        render: (row) => (row.is_general ? "Thine" : "Firm"),
      },
      {
        id: "source",
        numeric: false,
        disablePadding: false,
        label: "Source",
        isVisible: () => !(isEntryLevelJob || isDevelopmental),
        getValue: (row) => candidateSourceLabelMap[row.source],
        render: (row, index) => (
          <CandidateStatusMenu
            key={index}
            status={row.source}
            statusType="source"
            menuItemsMap={candidateSourceLabelMap}
            onChange={async (newValue) => {
              const response = await fetch(
                `/api/client-candidate-assessment-mapping/${row.id}`,
                {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ source: newValue }),
                }
              );

              if (response.status === 200) {
                // update table data at [i].hiringStatus
                const { tableData } = this.state;

                this.setState({
                  sendMessageResponse: {
                    ...this.state.sendMessageResponse,
                    open: true,
                    message: "Candidate Source updated successfully!",
                    severity: "success",
                  },
                  tableData: [
                    ...tableData.slice(0, index),
                    { ...tableData[index], source: newValue },
                    ...tableData.slice(index + 1),
                  ],
                });
              } else {
                this.setState({
                  sendMessageResponse: {
                    ...this.state.sendMessageResponse,
                    open: true,
                    message: "an error occurred updating candidate source",
                    severity: "error",
                  },
                });
              }
            }}
          />
        ),
      },
      {
        id: "prior-experience",
        numeric: false,
        disablePadding: false,
        label: "Prior Experience",
        width: 100,
        isVisible: () => isLateral || isIntegration,
        getValue: (row) => priorExperienceLabelMap[row.prior_experience],
        render: (row, index) => (
          <CandidateStatusMenu
            key={index}
            status={row.prior_experience}
            statusType="priorExperience"
            menuItemsMap={priorExperienceLabelMap}
            onChange={async (newValue) => {
              const response = await fetch(
                `/api/client-candidate-assessment-mapping/${row.id}`,
                {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ priorExperience: newValue }),
                }
              );

              if (response.status === 200) {
                // update table data at [i].hiringStatus
                const { tableData } = this.state;

                this.setState({
                  sendMessageResponse: {
                    ...this.state.sendMessageResponse,
                    open: true,
                    message: "Candidate prior experience updated successfully!",
                    severity: "success",
                  },
                  tableData: [
                    ...tableData.slice(0, index),
                    { ...tableData[index], prior_experience: newValue },
                    ...tableData.slice(index + 1),
                  ],
                });
              } else {
                this.setState({
                  sendMessageResponse: {
                    ...this.state.sendMessageResponse,
                    open: true,
                    message:
                      "an error occurred updating candidate prior experience",
                    severity: "error",
                  },
                });
              }
            }}
          />
        ),
      },
      {
        id: "coachId",
        numeric: false,
        disablePadding: false,
        label: "Coach",
        width: 150,
        isVisible: () => isDevelopmental && coachFeatureEnabled,
        getValue: (row) => coachMap[row.coach_id],
        render: (row, index) => (
          <CandidateStatusMenu
            key={index}
            status={row.coach_id}
            menuItemsMap={coachMap}
            onChange={async (newValue) => {
              const response = await fetch(
                `/api/client-candidate-assessment-mapping/${row.id}`,
                {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ coachId: newValue }),
                }
              );

              if (response.status === 200) {
                // update table data at [i].hiringStatus
                const { tableData } = this.state;

                this.setState({
                  sendMessageResponse: {
                    ...this.state.sendMessageResponse,
                    open: true,
                    message: "Coach updated successfully!",
                    severity: "success",
                  },
                  tableData: [
                    ...tableData.slice(0, index),
                    { ...tableData[index], hiring_status: newValue },
                    ...tableData.slice(index + 1),
                  ],
                });
              } else {
                this.setState({
                  sendMessageResponse: {
                    ...this.state.sendMessageResponse,
                    open: true,
                    message: "an error occurred updating coach",
                    severity: "error",
                  },
                });
              }
            }}
          />
        ),
      },
      {
        id: "graduationDate",
        numeric: false,
        disablePadding: false,
        label: "Graduation Date",
        width: 80,
        isVisible: () => isGeneralJob,
        getValue: (row) => row.graduation_date,
        render: (row, index) =>
          new Date(row.graduation_date).toLocaleDateString(),
      },
      {
        id: "appliedOn",
        numeric: false,
        disablePadding: false,
        label: "Invited On",
        width: 80,
        isVisible: () => !isGeneralJob,
        getValue: (row) => row.assign_dt,
        render: (row, index) => new Date(row.assign_dt).toLocaleDateString(),
      },
      {
        id: "lastContacted",
        numeric: false,
        disablePadding: false,
        label: "Last Contacted",
        width: 80,
        isVisible: () => !isGeneralJob,
        getValue: (row) => row.last_contacted,
        render: (row, index) =>
          new Date(row.last_contacted).toLocaleDateString(),
      },
      {
        id: "score",
        numeric: true,
        disablePadding: false,
        label: "Score",
        width: 40,
        isVisible: () => !isDevelopmental,
        getValue: (row) =>
          getCandidateUsage(row, isEntryLevelJob) == "completed"
            ? row.score_value
            : "NA",
        render: (row, index) => {
          if (getCandidateUsage(row, isEntryLevelJob) == "completed") {
            return (
              <CandidateScore
                key={index}
                scoreType={scoreType}
                score={row.score_value ? Number(row.score_value) : ""}
              />
            );
          }

          return "NA";
        },
      },
      {
        id: "usage",
        numeric: false,
        disablePadding: false,
        label: "Assessment Status",
        width: 50,
        isVisible: () => !isGeneralJob,
        getValue: (row) => getCandidateUsage(row, isEntryLevelJob),
        render: (row, index) => (
          <CandidateUsage
            key={index}
            usage={getCandidateUsage(row, isEntryLevelJob)}
          />
        ),
      },
      {
        id: "reportLink",
        numeric: false,
        disablePadding: false,
        label: "Report Link",
        width: 80,
        isVisible: () => isDevelopmental,
        getValue: (row) => (row.is_general ? "Thine" : "Firm"),
        render: (row) => {
          const usage = getCandidateUsage(row, isEntryLevelJob);

          return usage == "completed" ? (
            <Box>
              <Button
                size="small"
                variant="contained"
                style={{
                  backgroundColor: "#03629c",
                  textTransform: "capitalize",
                }}
                onClick={() =>
                  this.props.history.push(`/firm/report/${row.id}`)
                }
              >
                View Report
              </Button>
              {/* [PL-550] Removing copy button */}
              {/* <Tooltip title="Copy To Clipboard">
              <IconButton onClick={(event) => {
                navigator.clipboard.writeText(`${window.location.origin}/firm/report/${row.id}`);
                event.stopPropagation();
              }}>
                <CopyIcon />
              </IconButton>
            </Tooltip> */}
            </Box>
          ) : (
            "-"
          );
        },
      },
      {
        id: "candidateStatus",
        numeric: false,
        disablePadding: false,
        label: "Candidate Status",
        width: 50,
        isVisible: () => !(isIntegration || isGeneralJob || isDevelopmental),
        getValue: (row) => hiringStatusLabelMap[row.hiring_status],
        render: (row, index) => (
          <CandidateStatusMenu
            key={index}
            status={row.hiring_status}
            statusType="hiringStatus"
            menuItemsMap={hiringStatusLabelMap}
            onChange={async (newValue) => {
              const response = await fetch(
                `/api/client-candidate-assessment-mapping/${row.id}`,
                {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ hiringStatus: newValue }),
                }
              );

              if (response.status === 200) {
                // update table data at [i].hiringStatus
                const { tableData } = this.state;

                this.setState({
                  sendMessageResponse: {
                    ...this.state.sendMessageResponse,
                    open: true,
                    message: "Candidate status updated successfully!",
                    severity: "success",
                  },
                  tableData: [
                    ...tableData.slice(0, index),
                    { ...tableData[index], hiring_status: newValue },
                    ...tableData.slice(index + 1),
                  ],
                });
              } else {
                this.setState({
                  sendMessageResponse: {
                    ...this.state.sendMessageResponse,
                    open: true,
                    message: "an error occurred updating candidate status",
                    severity: "error",
                  },
                });
              }
            }}
          />
        ),
      },
      {
        id: "",
        numeric: false,
        disablePadding: false,
        label: "",
        width: 30,
        isVisible: () => true,
        getValue: (row) => row.school,
        render: (row, index) => (
          <TableMenu
            key={index}
            assignmentId={row.assignment_id}
            usage={getCandidateUsage(row, isEntryLevelJob)}
            status={row.status}
            id={row.id}
            jobId={jobId}
            job={job}
            candidateName={row.client_candidate_name || row.candidate_name}
            index={index}
            isIntegration={isIntegration}
            isLateral={isLateral}
            isGeneralJob={isGeneralJob}
            setParentState={(newState) => this.setState(newState)}
            removeRow={() => {
              const { tableData } = this.state;
              const newTableData = [...tableData];
              newTableData.splice(index, 1);
              this.setState({ tableData: newTableData });
            }}
            setMessage={(message, severity) =>
              this.setState({
                sendMessageResponse: {
                  ...this.state.sendMessageResponse,
                  open: true,
                  message,
                  severity,
                },
              })
            }
          />
        ),
      },
    ];

    return columns.filter((cell) => cell.isVisible());
  };

  render() {
    const { tableData, assessment_type } = this.state;
    const isEntryLevelJob = assessmentValues.entryLevel.includes(
      assessment_type
    );
    const isLateral = assessmentValues.lateral.includes(
      assessment_type
    );
    const isIntegration = assessmentValues.integration.includes(
      assessment_type
    );
    const isDevelopmental = assessmentValues.developmental.includes(
      assessment_type
    );

    const isGeneralJob = !!this.state.job?.parentId;
    const columns = this.getColumns({
      isIntegration,
      isLateral,
      isGeneralJob,
      isDevelopmental,
      isEntryLevelJob,
    });
    const title = assessmentValues.entryLevel.includes(
      this.state.assessment_type
    )
      ? "Candidates"
      : "Associates";

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const showGenerateNorms = params.norms;

    return (
      <div>
        {this.state.isLoaded ? (
          <Box>
            <BackButton mb={1} />
            {this.state.modalOpen && (
              <EditModal
                handleCancel={() => {
                  this.setState({ modalOpen: false });
                }}
                title="Edit Candidate"
              >
                <TextField
                  fullWidth="true"
                  sx={{ marginBottom: "16px" }}
                  id="outlined-name"
                  label="Name"
                  value={this.state.candidateName}
                  onChange={(e) => {
                    this.setState({ candidateName: e.target.value });
                  }}
                />
                <Button
                  fullWidth="true"
                  variant="contained"
                  onClick={async () => {
                    let {
                      candidateName,
                      candidateId,
                      selectedIndex,
                      tableData,
                    } = this.state;
                    candidateName = candidateName.trim();
                    const firstName = candidateName.split(" ")[0];
                    const lastName =
                      candidateName.split(" ").length > 1
                        ? candidateName.substr(candidateName.indexOf(" ") + 1)
                        : "";
                    const newTabledata = [
                      ...tableData.slice(0, selectedIndex),
                      {
                        ...tableData[selectedIndex],
                        client_candidate_name: `${firstName} ${lastName}`,
                      },
                      ...tableData.slice(selectedIndex + 1),
                    ];
                    const response = await fetch(
                      `/api/client-candidate-assessment-mapping/${candidateId}`,
                      {
                        method: "PUT",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ firstName, lastName }),
                      }
                    );

                    if (response.status === 200) {
                      // update table data at [i].hiringStatus
                      this.setState({
                        sendMessageResponse: {
                          ...this.state.sendMessageResponse,
                          open: true,
                          message: "Candidate Name updated successfully!",
                          severity: "success",
                        },
                        tableData: newTabledata,
                        modalOpen: false,
                      });
                    } else {
                      this.setState({
                        sendMessageResponse: {
                          ...this.state.sendMessageResponse,
                          open: true,
                          message: "an error occurred updating candidate Name",
                          severity: "error",
                        },
                        modalOpen: false,
                      });
                    }
                  }}
                >
                  submit
                </Button>
              </EditModal>
            )}
            <SortableTable
              columns={columns}
              rows={tableData}
              title={`${tableData.length} ${title}, ${this.state.job.title} `}
              emptyTableMessage="No candidates available"
              downloadAllEntriesAsCsv={this.toCsv}
              setSelectedItems={(selectedItems, rows) => {
                this.setState({ selectedItems: rows });
              }}
              defaultOrderBy="name"
              setSelected={(selected) =>
                this.setState({ numSelected: selected })
              }
            >
              {this.state.numSelected > 0 ? (
                <Stack spacing={2} direction="row">
                  {showGenerateNorms && (
                    <Button
                      variant="outlined"
                      onClick={() =>
                        this.generateNorms(this.state.selectedItems)
                      }
                    >
                      Generate Norms
                    </Button>
                  )}
                  <Button
                    sx={{
                      width: "fit-content",
                    }}
                    variant="outlined"
                    onClick={() => this.toCsv(this.state.selectedItems)}
                  >
                    Download&nbsp;Selected&nbsp;Rows
                  </Button>
                </Stack>
              ) : (
                <Stack
                  spacing={0}
                  direction={{ xs: "column", sm: "row" }}
                  sx={{
                    alignItems: "center",
                    justifyContent: "flex-end",
                    margin: "4px",
                  }}
                // divider={<Divider orientation="vertical" flexItem />}
                >
                  <Stack
                    spacing={0}
                    direction="row"
                    sx={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: { xs: "300px", sm: "500px" },
                    }}
                  >
                    <TextField
                      size="small"
                      InputProps={{
                        style: { marginRight: "4px", width: "99%" },
                        startAdornment: (
                          <SearchIcon fontSize="small" color="info" />
                        ),
                      }}
                      onChange={(e) => {
                        this.searchTable(e);
                      }}
                      value={this.state.searchString}
                      placeholder="Search..."
                    />
                    <Select
                      size="small"
                      InputProps={{
                        style: { marginRight: "28px" },
                      }}
                      defaultValue={"any"}
                      onChange={this.searchBy}
                    >
                      <MenuItem value="any">Any</MenuItem>
                      <MenuItem value="name">Name</MenuItem>
                      <MenuItem value="email">Email</MenuItem>
                    </Select>
                  </Stack>
                  <Button
                    sx={{ marginLeft: "14px" }}
                    variant="text"
                    onClick={this.toCsv}
                  >
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        textTransform: "none",
                      }}
                    >
                      Download&nbsp;all
                    </Typography>
                    <DownloadForOfflineRoundedIcon
                      color="primary"
                      sx={{
                        fontSize: "48px",
                        marginLeft: "10px",
                        color: "#45aae1",
                      }}
                    />
                  </Button>
                </Stack>
              )}
            </SortableTable>
          </Box>
        ) : (
          <Loader minHeight={"100vh"} />
        )}
        <BasicSnackBar
          snackbarOpen={this.state.sendMessageResponse.open}
          setSnackbarOpen={(value) =>
            this.setState({
              sendMessageResponse: {
                ...this.state.sendMessageResponse,
                open: value,
              },
            })
          }
          message={this.state.sendMessageResponse.message}
          severity={this.state.sendMessageResponse.severity}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firm: state.firm,
  };
};

export default connect(mapStateToProps, null)(withRouter(ClientSearch));
