import React, { Component } from "react";
import moment from "moment";
import "../css/fontawesome-v6/css/all.css";
import "../css/OCIAltReport.scss";
import Zoomer from "../Components/Zoomer";
import NotesBlock from "../Reports/Components/Developmental/NotesBlock";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { assetsBase64 } from "./Components/Developmental/constants";

const Header = () => {
  return (
    <div className="page-header">
      <img className="logo" src={assetsBase64.logo} />
      <span>Modern Lawyer Competency Framework</span>
    </div>
  );
};

const Footer = (props) => {
  return (
    <div className="page-footer">
      <span>
        Thine | {props.name} | {props.pageNum}
      </span>
    </div>
  );
};

const Strengths = ({ data }) => {
  const items = data.map((str, i) => {
    return (
      <div key={i + 1} className="competency-panel">
        <div className="heading bg-strength">
          <h2>{str.indicator_name}</h2>
        </div>
        <div className="inner">
          <p>{str.indicator_description}</p>
          <div className="icon-contain">
            <span
              className={"fa-solid " + str.icon_class + " fg-strength"}
            ></span>
          </div>
        </div>
      </div>
    );
  });

  return <div className="competency-panels">{items}</div>;
};

const Priority = ({ priorities, actionItems }) => {
  const { indicator_id } = priorities[0];
  const items = actionItems
    .filter((item) => item.indicator_id == indicator_id)
    .map((item, i) => {
      return (
        <li key={i + 1}>
          <span>{item.meta_text}</span>
        </li>
      );
    });
  return (
    <>
      <div className="competency-panel">
        <div className="heading bg-priority">
          <h2>{priorities[0].indicator_name}</h2>
        </div>
        <div className="inner">
          <p>{priorities[0].indicator_description}</p>
          <div className="icon-contain">
            <span
              className={
                "fa-solid " + priorities[0].icon_class + " fg-priority"
              }
            ></span>
          </div>
        </div>
      </div>
      <div className="competency-panel">
        <div className="heading bg-priority">
          <h2>Action Items</h2>
        </div>
        <div className="inner">
          <ul>{items}</ul>
        </div>
      </div>
    </>
  );
};

class OCIAlt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadProgress: 0,
      isDownloading: false,
    };
  }

  componentDidMount() {
    const fileName =
      this.props.data.user.first_name +
      " " +
      this.props.data.user.last_name +
      " Professional Competency Index Report  " +
      moment(this.props.data.user.completed_dt).format("MM-DD-YYYY") +
      ".pdf";
    this.setState({
      isLoaded: true,
      scoreData: this.props.data,
      fileName,
    });
  }
  handleDownload = async () => {
    const pdfSections = document.getElementsByClassName("pdfSections");
    const totalPages = pdfSections.length;
    const updateProgress = (page) => {
      page = page + 1;
      let percentLoaded = (page / totalPages) * 100;
      this.setState({ downloadProgress: percentLoaded });
    };
    const boxShadows = document.querySelectorAll(".box-shadow-no-export");

    boxShadows.forEach(function (el) {
      el.classList.remove("box-shadow-no-export");
    });

    this.setState({ isDownloading: true });
    const pageWidth = 960;
    const pageHeight = 1357;
    let pdf = new jsPDF("p", "px", "a4", true);
    pdf.setFillColor("#ffffff");

    let PDFPagesHtml = [];
    let PDFSinglePageHtml = [];
    for (let i = 0; i < pdfSections.length; i++) {
      PDFPagesHtml.push(PDFSinglePageHtml);
      PDFSinglePageHtml = [];
      PDFSinglePageHtml.push(pdfSections[i]);

      if (i === pdfSections.length - 1 && PDFSinglePageHtml.length > 0) {
        PDFPagesHtml.push(PDFSinglePageHtml);
      }
    }

    for (let i = 0; i < PDFPagesHtml.length; i++) {
      if (i > 1) {
        await pdf.addPage("a4", "p");
      }
      for (let f = 0; f < PDFPagesHtml[i].length; f++) {
        document
          .getElementById("downloadReport")
          .appendChild(PDFPagesHtml[i][f].cloneNode(true));
        updateProgress(i);
      }
      const html = document.getElementById("downloadReport");
      const canvas = await html2canvas(html, {
        width: pageWidth,
        height: pageHeight,
        x: 0,
        y: 0,
        windowWidth: pageWidth,
        windowHeight: pageHeight,
      });
      let canvasHolder = document.createElement("div");
      canvasHolder.setAttribute("id", "temporaryCanvas");
      document.body.appendChild(canvasHolder);
      canvasHolder.appendChild(canvas);
      let img = await canvas.toDataURL("image/jpeg", 0.5);
      const imgProps = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      html.innerHTML = "";
      await pdf.addImage(
        img,
        "JPEG",
        0,
        0,
        pdfWidth,
        pdfHeight,
        undefined,
        "FAST"
      );
      document.getElementById("temporaryCanvas").remove();
    }
    // document.getElementById("downloadReport").style.paddingTop = "";
    await pdf.save(this.state.fileName);
    document.getElementById("downloadReport").replaceChildren();
    boxShadows.forEach(function (el) {
      el.classList.add("box-shadow-no-export");
    });

    this.setState({ isDownloading: false, downloadProgress: 0 });
  };

  render() {
    return (
      <div id="ocialtcontain">
        <Zoomer
          progressVariant="indeterminate"
          download={this.handleDownload}
          targetId="ocialt"
        >
          <div id="ocialt" className="reportContainer">
            {/* cover */}
            <div className="pdfSections box-shadow-no-export cover">
              <div className="container">
                <img src={assetsBase64.banner} className="banner" />
                <div className="content">
                  <div className="section">
                    <img src={assetsBase64.logo} />
                  </div>
                  <div className="section">
                    <h1>Modern Lawyer Competency Framework</h1>
                    <div className="divider"></div>
                    <h2>
                      <strong>Prepared For: </strong>
                      {this.props.data.user.first_name}{" "}
                      {this.props.data.user.last_name}
                    </h2>
                    <h2>
                      <strong>In Partnership With: </strong>
                      {this.props.data.assessment_meta.client_name}
                    </h2>
                  </div>
                  <div className="section">
                    <h3>&copy; 2023 Thine Solutions, Inc.</h3>
                    <h3>
                      <strong>Date:</strong>{" "}
                      {moment(this.props.data.user.completed_dt).format(
                        "MM.DD.YYYY"
                      )}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            {/* intro text 1 */}
            <div className="pdfSections box-shadow-no-export">
              <Header />
              <div className="page-section">
                <div className="text-block">
                  <h4>Welcome</h4>
                  <p>
                    Decades of Industrial and Organizational (I/O) Psychology
                    research consistently upholds the predictive power of, and
                    potential to minimize bias through measuring, workplace
                    competencies. Competencies provide descriptions of the
                    characteristics, qualities, and skills that a person needs
                    to be successful in a specific job. Combining insights from
                    I/O Psychology and over a decade of research on lawyer
                    competencies (e.g., as published in Berman, Bock, & Aiken,
                    2016), Thine has developed a unique model and assessment of
                    the competencies essential for success as an associate. We
                    measure the competencies in our model using our Modern
                    Lawyer Competency Framework (MLCF). The MLCF identifies
                    lawyers’ top strengths and development priorities, helping
                    lawyers identify where they might thrive. It can also help
                    you understand the various elements of relevant workplace
                    behaviors and empower you to identify those resources that
                    can help accelerate your growth and lead to increased job
                    satisfaction and wellbeing.
                  </p>
                  <div className="divider"></div>
                  <h4>About the Modern Lawyer Competency Framework</h4>
                  <p>
                    The MLCF is comprised of 180 psychometric statements that are linked to a total of 22 different professional competencies. The MLCF was developed through an extensive review of best practices in organizational, social, and personality psychology (as well as our own rigorous research in-house). The competencies assessed by the MLCF, and the items that are used to assess them, were selected based on their face validity, lack of bias, and internal consistency reliability.
                  </p>
                  <div className="footnotes">
                    <ul>
                      <li>
                        Berman, Bock, & Aiken’s (2016) “Accelerating Lawyer Success: How to Make Partner, Stay Healthy, and Flourish in a Law Firm.”
                      </li>
                      <li>
                        Shultz & Zedeck’s (2011) “Predicting Lawyer
                        Effectiveness: Broadening the Basis for Law School Admission Decisions”
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <Footer
                pageNum="1"
                name={
                  this.props.data.user.first_name +
                  " " +
                  this.props.data.user.last_name
                }
              />
            </div>
            {/* intro text 2 */}
            <div className="pdfSections box-shadow-no-export">
              <Header />
              <div className="page-section">
                <div className="text-block">
                  <div className="divider"></div>
                  <h4>Understanding Your Unique Report</h4>
                  <p>
                    Competencies are malleable and may change over time. Your report is a snapshot of where you are currently and can be used as a professional development tool to set future goals and priorities. Having clarity around where you excel and where you can improve will enable you to create a concrete strategy to get you where you want to be. The report is broken out into three sections: 
                    <ol>
                      <li>
                        <span>Your top three strengths</span>
                      </li>
                      <li>
                        <span>Your top developmental priority to focus on and suggestions for improvement</span>
                      </li>
                      <li>
                        <span>An exercise that provides an opportunity for you to reflect on your development priorities and set actionable goals for each. </span>
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
              <Footer
                pageNum="2"
                name={
                  this.props.data.user.first_name +
                  " " +
                  this.props.data.user.last_name
                }
              />
            </div>
            {/* key strengths */}
            <div className="pdfSections box-shadow-no-export">
              <Header />
              <div className="text-block">
                <h4>Key Strengths</h4>
                <p>
                  Here are a few of the competencies that your assessment
                  suggests would be your core strengths in the workplace.
                </p>
              </div>
              <Strengths
                data={this.props.data.strengths}
                questions={this.props.data.strength_qs}
              />
              <Footer
                pageNum="3"
                name={
                  this.props.data.user.first_name +
                  " " +
                  this.props.data.user.last_name
                }
              />
            </div>
            {/* developmental priority */}
            <div className="pdfSections box-shadow-no-export">
              <Header />
              <div className="page-section">
                <div className="text-block">
                  <h4>Your Developmental Priority</h4>
                  <p>
                    Here is one competency that your assessment suggests would
                    be an opportunity for growth and development. 
                  </p>
                  <Priority
                    priorities={this.props.data.priorities}
                    actionItems={this.props.data.priority_actions}
                  />
                </div>
              </div>
              <Footer
                pageNum="5"
                name={
                  this.props.data.user.first_name +
                  " " +
                  this.props.data.user.last_name
                }
              />
            </div>

            {/* leveling up (smart acronym) */}
            <div className="pdfSections box-shadow-no-export">
              <Header />
              <div className="page-section">
                <div className="text-block">
                  <h4>Leveling Up</h4>
                  <p>
                    Over the course of your career, there will be many
                    opportunities to explore what is important to you and what
                    steps you should take to advance yourself in the profession.
                    Learning how to set developmental goals and how to create
                    strategic, organized action steps will be critical to the
                    success of your vision.
                  </p>

                  <p>
                    Looking at the development priority and action steps
                    identified in this report, take a moment to think about what
                    progress would look like. What goals can you set to help you
                    achieve desired outcomes? Using the below SMART framework,
                    set actionable goals.
                  </p>

                  <h5>SMART Goal-Setting Framework:</h5>
                  <div className="mnemonic">
                    <h5>
                      <span className="letter">S</span>
                      <span className="indent">Specific</span>
                    </h5>

                    <h5>
                      <span className="letter">M</span>
                      <span className="indent">Measurable</span>
                    </h5>

                    <h5>
                      <span className="letter">A</span>
                      <span className="indent">Achievable</span>
                    </h5>

                    <h5>
                      <span className="letter">R</span>
                      <span className="indent">Realistic/Relevant</span>
                    </h5>

                    <h5>
                      <span className="letter">T</span>
                      <span className="indent">Time-bound</span>
                    </h5>
                  </div>

                  <p>What do you want to achieve?</p>

                  <p>
                    How will you know when you've achieved it? How will you
                    measure success?
                  </p>

                  <p>Does it feel attainable for you?</p>

                  <p>How is it connected to your larger career goals?</p>
                  <p>
                    What is the time frame in which you would like to achieve
                    it?
                  </p>
                </div>
              </div>
              <Footer
                pageNum="6"
                name={
                  this.props.data.user.first_name +
                  " " +
                  this.props.data.user.last_name
                }
              />
            </div>
            <div className="pdfSections box-shadow-no-export">
              <Header />
              <div className="text-block text-block-bottom">
                <h4>Building on Your Results</h4>
                <p>
                  The space below can be used to free flow or jot down ideas,
                  reflections, and goals that surfaced while looking at or
                  debriefing your Report. We have included sample questions from
                  the SMART Framework to guide you in this process.
                </p>
                <p>
                  <strong>What do you want to achieve?</strong>
                  <br />
                  <strong>
                    How will you know when you've achieved it? How will you
                    measure success?
                  </strong>
                  <br />
                  <strong>Does it feel attainable for you?</strong>
                  <br />
                  <strong>
                    How is it connected to your larger career goals?
                  </strong>
                  <br />
                  <strong>
                    What is the time frame in which you would like to achieve
                    it?
                  </strong>
                  <br />
                </p>
              </div>
              <br />
              <NotesBlock />
              <Footer
                pageNum="7"
                name={
                  this.props.data.user.first_name +
                  " " +
                  this.props.data.user.last_name
                }
              />
            </div>
          </div>
        </Zoomer>
        <div id="downloadReport" className="reportContainer"></div>
      </div>
    );
  }
}

export default OCIAlt;
