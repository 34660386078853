import { Link } from "react-router-dom";
import {
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SortableTable from "../../../Components/SortableTable";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddEditClientModal from "./AddEditClientModal";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

const ITEM_HEIGHT = 48;

const createTableElement = (component, value) => ({ component, value });

const TableMenu = ({ clientInfo, setSelectedClient, setModalOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "29ch",
          },
        }}
      >
        <MenuItem
          selected={false}
          onClick={() => {
            handleClose();
            setModalOpen(true);
            setSelectedClient(clientInfo);
          }}
        >
          <Typography variant="inherit" noWrap>
            Edit Client
          </Typography>
        </MenuItem>
        <Link
          to={{ pathname: "/admin/surveydata", state: clientInfo }}
          style={{ color: "#212121" }}
        >
          <MenuItem selected={false}>
            <Typography variant="inherit" noWrap>
              Survey Data
            </Typography>
          </MenuItem>
        </Link>
        <Link
          to={{ pathname: "/admin/client/manage", state: clientInfo }}
          style={{ color: "#212121" }}
        >
          <MenuItem selected={false}>
            <Typography variant="inherit" noWrap>
              Manage Client
            </Typography>
          </MenuItem>
        </Link>
        <Link
          to={{
            pathname: `/admin/client/${clientInfo.clientId}/subscription`,
          }}
          style={{ color: "#212121" }}
        >
          <MenuItem selected={false}>
            <Typography variant="inherit" noWrap>
              Manage Subscription
            </Typography>
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
};

const ClientSupport = () => {
  const [selectedClient, setSelectedClient] = React.useState(-1);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(2000);
  const [tableData, setTableData] = useState([]);
  const [numSelected, setNumSelected] = useState(0);
  const [isClientUpdated, setIsClientUpdated] = useState(true);

  useEffect(() => {
    const fetchRows = async () => {
      const response = await fetch(`/api/clients`);
      const data = await response.json();
      setTableData(data.rows);
      setIsClientUpdated(false);
    };
    fetchRows();
  }, [pageNumber, pageSize, isClientUpdated]);

  const getColumns = () => {
    const columns = [
      {
        id: "clientId",
        numeric: false,
        disablePadding: false,
        label: "ID",
        width: 250,
        isVisible: () => true,
        getValue: (row) => row.clientId,
        render: (row) => row.clientId,
      },
      {
        id: "logo",
        numeric: false,
        disablePadding: false,
        label: "Logo",
        isVisible: () => true,
        getValue: (row) => row.logo,
        render: (row) => (
          <img src={row.logoUrl} style={{ maxWidth: "100px" }} />
        ),
      },
      {
        id: "clientName",
        numeric: false,
        disablePadding: false,
        label: "Name",
        isVisible: () => true,
        getValue: (row) => row.clientName,
        render: (row) => (
          <div data-cy={`client-id-${row.clientId}`}>
            {row.clientName}
          </div>
        ),
      },
      {
        id: "clientDescription",
        numeric: false,
        disablePadding: false,
        label: "Description",
        isVisible: () => true,
        getValue: (row) => row.clientDescription,
        render: (row) => row.clientDescription,
      },
      {
        id: "parentGroup",
        numeric: false,
        disablePadding: false,
        label: "Parent Group",
        isVisible: () => true,
        getValue: (row) => row.parentGroup,
        render: (row) => row.parentGroup,
      },
      {
        id: "createdAt",
        numeric: false,
        disablePadding: false,
        label: "Created At",
        isVisible: () => true,
        getValue: (row) => row.createdAt,
        render: (row) => new Date(row.createdAt).toLocaleDateString(),
      },
      {
        id: "updatedAt",
        numeric: false,
        disablePadding: false,
        label: "Updated At",
        isVisible: () => true,
        getValue: (row) => row.updatedAt,
        render: (row) => new Date(row.updatedAt).toLocaleDateString(),
      },
      {
        id: "",
        numeric: false,
        disablePadding: false,
        label: "",
        width: 30,
        isVisible: () => true,
        getValue: (row) => row.school,
        render: (row, index) => (
          <TableMenu
            clientInfo={row}
            setSelectedClient={setSelectedClient}
            setModalOpen={setModalOpen}
          />
        ),
      },
    ];

    return columns.filter((cell) => cell.isVisible());
  };

  return (
    <>
      <SortableTable
        columns={getColumns()}
        rows={tableData}
        title={`${tableData.length} Clients`}
        downloadAllEntriesAsCsv={() => {}}
        defaultOrderBy="clientId"
        setSelected={setNumSelected}
      >
        <Stack
          spacing={2}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Button
            sx={{
              width: "fit-content",
            }}
            variant="contained"
            onClick={() => {
              setModalOpen(true);
              setSelectedClient(null);
            }}
          >
            <AddIcon />
            Create&nbsp;a&nbsp;new&nbsp;Client
          </Button>
        </Stack>
      </SortableTable>
      {modalOpen && (
        <AddEditClientModal
          clientInfo={selectedClient}
          closeModal={(updateData = false) => {
            setModalOpen(false);
            setIsClientUpdated(updateData);
          }}
        ></AddEditClientModal>
      )}
    </>
  );
};

export default ClientSupport;
