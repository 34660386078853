import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import App from "./App";
import ServerApp from "./ServerApp";
import { LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment';
// import DateAdapter from "@mui/x-date-pickers/AdapterDateFns"; // previous location and current path gives white screen-- switching to Moment for now

import { Provider } from 'react-redux';
import { useStore } from './redux/store';
import { fetchUserSession } from './redux/actions/user';
import { fetchFirmSession } from './redux/actions/firm';

function ReduxApp({ pageProps = {} }) {
  const store = useStore(pageProps.initialReduxState)
  
  React.useEffect(async () => {
    // This piece will only run on client side
    console.log("[MyApp] Mounted!!! store: ", store);
    store.dispatch(fetchUserSession());
    store.dispatch(fetchFirmSession());
  }, []);

  const pathname = _.get(window, "location.pathname", "");
  const isServerApp = pathname.indexOf("render/client/report") > -1;
  const CurrentApp = isServerApp ? <ServerApp /> : <App />

  return (
    <Provider store={store}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={moment}>
          {CurrentApp}
        </LocalizationProvider>
      </BrowserRouter>
    </Provider>
  )
}

ReactDOM.render(
  <ReduxApp />,
  document.getElementById("root")
);
