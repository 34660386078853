import React, { Component } from "react";
import _ from "lodash";
import { Box } from "@mui/material";
import EditModal from "../../../Components/EditModal";
import BasicControls from "../../../Components/BasicControls";
import BasicTextarea from "../../../Components/Inputs/BasicTextarea";
import BasicTextInput from "../../../Components/Inputs/BasicTextInput";
class AddClientUser extends Component {
  constructor(props) {
    super(props);
    const { clientInfo } = this.props;
    this.state = {
      isLoading: false,
      clientInfo: {
        clientId: _.get(clientInfo, "clientId", null),
        clientName: _.get(clientInfo, "clientName", ""),
        clientDescription: _.get(clientInfo, "clientDescription", ""),
        logoUrl: _.get(clientInfo, "logoUrl", ""),
        groupLevel: _.get(clientInfo, "groupLevel", 0),
        parentGroup: _.get(clientInfo, "parentGroup", 0),
      },
      snackbarOpen: false,
      responseSeverity: "success",
      responseMessage: "",
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const { clientInfo } = this.state;
    this.setState({
      clientInfo: {
        ...clientInfo,
        [name]: value,
      },
    });
  };

  handleTextAreaChange = (value, name) => {
    const { clientInfo } = this.state;
    this.setState({
      clientInfo: {
        ...clientInfo,
        [name]: value,
      },
    });
  };

  submit = () => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { clientInfo } = this.state;
        const { closeModal } = this.props;
        const { clientId } = clientInfo;

        try {
          if (clientId) {
            // Edit
            await fetch(`/api/clients/${clientId}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(clientInfo),
            });
          } else {
            // Create new
            await fetch("/api/clients", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(clientInfo),
            });
          }
          this.setState({ isLoading: false }, () => closeModal(true));
        } catch (error) {
          console.log("Error: ", error);
          this.setState({ isLoading: false });
        }
      }
    );
  };

  setSnackbarOpen = (val) => {
    this.setState({
      snackbarOpen: val,
    });
  };

  setResponseSeverity = (val) => {
    this.setState({
      responseSeverity: val,
    });
  };

  setResponseMessage = (val) => {
    this.setState({
      responseMessage: val,
    });
  };

  render() {
    const {
      isLoading,
      clientInfo,
      snackbarOpen,
      responseSeverity,
      responseMessage,
    } = this.state;
    const {
      clientId,
      clientName,
      clientDescription,
      logoUrl,
      groupLevel,
      parentGroup,
    } = clientInfo;
    const title = clientId ? "Edit Client" : "Create Client";
    const { closeModal } = this.props;

    return (
      <EditModal handleCancel={closeModal} title={title}>
        <BasicTextInput
          title={"Client Name"}
          placeholder={"Enter Client Name"}
          name={"clientName"}
          value={clientName}
          onChange={this.handleChange}
          style={{ mb: 1 }}
        />
        <BasicTextarea
          title={"Client Description"}
          placeholder={"Enter Description"}
          name={"clientDescription"}
          value={clientDescription || ""}
          textLimit={500}
          onChange={this.handleTextAreaChange}
          style={{ mb: 1 }}
        />
        <BasicTextInput
          title={"Logo Url"}
          placeholder={"Enter Client Logo Url"}
          name={"logoUrl"}
          value={logoUrl}
          onChange={this.handleChange}
          style={{ mb: 1 }}
        />
        <BasicTextInput
          title={"Parent Group"}
          placeholder={"Enter Parent Group"}
          name={"parentGroup"}
          value={parentGroup}
          onChange={this.handleChange}
          style={{ mb: 1 }}
        />
        <BasicControls
          isLoading={isLoading}
          onCancel={closeModal}
          onClick={this.submit}
          message={responseMessage}
          snackbarOpen={snackbarOpen}
          setSnackbarOpen={this.setSnackbarOpen}
          severity={responseSeverity}
          style={{ mb: 0 }}
        />
      </EditModal>
    );
  }
}

export default AddClientUser;
