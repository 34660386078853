import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ElevatedPaper from "../../../Components/ElevatedPaper";
import RectangleIcon from "@mui/icons-material/Rectangle";

const SurveyIndicators = ({ surveyData, surveyForm }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      minWidth: 275,
      position: "sticky",
      top: 0,
      background: "#f8fcff",
    }}
  >
    {surveyData.map((data) => (
      <Box
        sx={{ minWidth: "165px", minHeight: "165px", background: "#f8fcff" }}
        key={data.indicator_id}
      >
        <Box
          sx={{
            flexDirection: "column",
            display: "flex",
            border: "1px solid #a2d4f0",
            borderRadius: "5px",
            pb: "5px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: `${data.meta.color_code}`,
              color: "#fff",
              p: 1,
              borderTopLeftRadius: "5px",
              textAlign: "center",
              borderTopRightRadius: "5px",
              mb: "5px",
            }}
          >
            <Typography sx={{ fontSize: "0.7rem" }} variant="subtitle2">
              {data.indicator_name}
            </Typography>
          </Box>
          {data.children.map((child) => {
            const style = surveyForm[child.indicator_id]
              ? {
                  fontWeight: 700,
                }
              : {};
            return (
              <Box
                key={child.indicator_id}
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  paddingLeft: 1,
                  paddingRight: 1,
                }}
              >
                <Typography
                  sx={{ lineHeight: 1.9, fontSize: "0.7rem", ...style }}
                  key={child.indicator_id}
                  variant="subtitle2"
                >
                  {child.indicator_name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    ))}
  </Box>
);

export default SurveyIndicators;
