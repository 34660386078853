import React, {useMemo, useState} from "react";
import {useQuery} from "react-query";
import {Box, Button, Grid, TableSortLabel, Typography} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {visuallyHidden} from "@mui/utils";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import _ from "lodash";

import writeXlsxFile from 'write-excel-file'

type UserAnswer = {
    indicator_id: number
    answer_id: number
    answer_ref_id: number
    answer_order: number
    question_type: number
    final_score: string
    ranked_score: number
    indicator_name: string
    assign_id: number
    assessment_id: number
    question_id: number
    question_title: string
    question_description: string
    answer_title: string
    answer_description: string
    answer_value: string
    score_value: number
    answer_weight: string

}

function debugAssignment(assignment_id: string) {
    // console.log("Debug assignment", assignment_id)
    return fetch(`/api/admin/debug/assignment/${assignment_id}`, {
        headers: {
            accepts: "application/json",
        },
        method: "get",
    })
        .then((res) => res.json() as Promise<{ userAnswers: UserAnswer[], scores:any }>)
}

const columns = [
    {
        id: "question_type",
        disablePadding: false,
        label: "question_type",
        getValue: (row: UserAnswer) => row?.question_type,
    },
    {
        id: "question_id",
        disablePadding: false,
        label: "question_id",
        getValue: (row: UserAnswer) => row?.question_id,
    },
    {
        id: "question_title",
        disablePadding: false,
        label: "question_title",
        getValue: (row: UserAnswer) => row?.question_title,
        minWidth: 180,
    },
    {
        id: "answer_id",
        disablePadding: false,
        label: "answer_id",
        getValue: (row: UserAnswer) => row?.answer_id,
    },
    {
        id: "answer_order",
        disablePadding: false,
        label: "answer_order",
        getValue: (row: UserAnswer) => row?.answer_order,
    },
    {
        id: "answer_title",
        disablePadding: false,
        label: "answer_title",
        getValue: (row: UserAnswer) => row?.answer_title,
        minWidth: 180,
    },
    {
        id: "answer_ref_id",
        disablePadding: false,
        label: "answer_ref_id",
        getValue: (row: UserAnswer) => row?.answer_ref_id,
        minWidth: 180,
    },
    {
        id: "indicator_name",
        disablePadding: false,
        label: "indicator_name",
        getValue: (row: UserAnswer) => row?.indicator_name,
        minWidth: 180,
    },
    {
        id: "indicator_id",
        disablePadding: false,
        label: "indicator_id",
        getValue: (row: UserAnswer) => {
            return row?.indicator_id
        },
        minWidth: 120,
    },
    {
        id: "final_score",
        disablePadding: false,
        label: "final_score",
        getValue: (row: UserAnswer) => row?.final_score,
        minWidth: 180,
    },
    {
        id: "ranked_score",
        disablePadding: false,
        label: "ranked_score",
        getValue: (row: UserAnswer) => row?.ranked_score,
        minWidth: 180,
    },
    {
        id: "question_description",
        disablePadding: false,
        label: "question_description",
        getValue: (row: UserAnswer) => row?.question_description,
        minWidth: 180,
    },
    {
        id: "answer_description",
        disablePadding: false,
        label: "answer_description",
        getValue: (row: UserAnswer) => row?.answer_description,
        minWidth: 180,
    },
    {
        id: "assign_id",
        disablePadding: false,
        label: "assign_id",
        getValue: (row: UserAnswer) => row?.assign_id,
        minWidth: 180,
    },
    {
        id: "assessment_id",
        disablePadding: false,
        label: "assessment_id",
        getValue: (row: UserAnswer) => row?.assessment_id,
        minWidth: 180,
    },
    {
        id: "answer_value",
        disablePadding: false,
        label: "answer_value",
        getValue: (row: UserAnswer) => row?.answer_value,
        minWidth: 180,
    },
    {
        id: "score_value",
        disablePadding: false,
        label: "score_value",
        getValue: (row: UserAnswer) => row?.score_value,
        minWidth: 180,
    },
    {
        id: "answer_weight",
        disablePadding: false,
        label: "answer_weight",
        getValue: (row: UserAnswer) => row?.answer_weight,
        minWidth: 180,
    },


]

const descendingComparator = (a: object, b: object, order: 'asc' | 'desc', orderBy: string, columns: any) => {
    const getValue = _.find(columns, {id: orderBy})?.getValue;
    const A =
        typeof getValue(a) === "string" ? getValue(a).toUpperCase() : getValue(a); // ignore upper and lowercase
    const B =
        typeof getValue(b) === "string" ? getValue(b).toUpperCase() : getValue(b); // ignore upper and lowercase

    if (A === null && B === null) return 0;
    if (A === null) return 1;
    if (B === null) return -1;

    // General comparison
    if (order === 'asc') return A < B ? -1 : (A > B ? 1 : 0);
    else return A < B ? 1 : (A > B ? -1 : 0);
}

export function DebugAssignment({match: {params: {assignment_id}}}: any) {
    const query = useQuery({queryKey: ['assignment', assignment_id], queryFn: () => debugAssignment(assignment_id)})

    const [orderBy, setOrderBy] = useState('question_id');
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');


    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedUserAnswers = useMemo(() => {
        // console.log("sorted", query.data?.userAnswers)
        if (!query.data?.userAnswers) return []

        return query.data.userAnswers.sort((a, b) => {
            return descendingComparator(a, b, order, orderBy, columns)
        })
    }, [query.data?.userAnswers, order, orderBy])

    function showJson() {
        const fileName = `debug-assignment-${assignment_id}.json`
        const json = JSON.stringify(sortedUserAnswers, null, 2)
        const blob = new Blob([json], {type: 'application/json'})
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        // a.download = fileName
        a.target = '_blank'
        a.click()
    }

    function downloadExcel() {
        const fileName = `debug-assignment-${assignment_id}.xlsx`
        makeExcel(fileName, sortedUserAnswers)
    }

    if (query.isLoading) return <p>Loading...</p>

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                sx={{mb: 1}}
            >
                <Typography variant="h4">
                    Debug Assignment: {assignment_id}
                </Typography>
                <div>
                    <Button
                        variant="contained"
                        sx={{marginRight: 1}}
                        onClick={showJson}
                    >
                        <DownloadIcon/> Show JSON
                    </Button>
                    <Button
                        variant="contained"
                        sx={{marginRight: 0}}
                        onClick={downloadExcel}
                    >
                        <DownloadIcon/> Download Excel
                    </Button>
                </div>
            </Grid>

            <TableContainer sx={{height: '50vh'}}>
                <Table sx={{borderCollapse: "separate"}}>
                    {/*<TableHead sx={{backgroundColor: "#5d2a5f", color: "#fff", position:'sticky', top:0, zIndex:99}}>*/}
                    <TableHead sx={{backgroundColor: "#5d2a5f", color: "#fff", position: 'sticky', top: 0, zIndex: 99}}>
                        <TableRow>
                            {columns.map((column, ind) => (
                                <TableCell
                                    key={ind}
                                    align={"left"}
                                >
                                    <TableSortLabel
                                        sx={{
                                            color: "#fff !important",
                                            "&:hover": {
                                                color: "#fff!important",
                                            },
                                            "&.Mui-active": {
                                                color: "#fff !important",
                                            },
                                            "&.Mui-active svg": {
                                                color: "#fff !important",
                                            },
                                            minWidth: column?.minWidth,

                                        }}
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}
                                    >
                                        {column.label}
                                        {orderBy === column.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === "desc"
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </Box>
                                        ) : null}

                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedUserAnswers.map((row: any, index: number) => {
                            return (
                                <TableRow key={index}>
                                    {columns.map((column, ind) => (
                                        <TableCell key={ind} align={"left"}>
                                            {column.getValue(row)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <pre>
                {JSON.stringify(query.data?.scores, null, 2)}
            </pre>

        </>
    )
}


async function makeExcel(fileName: string, userAnswers: UserAnswer[]) {
    const userAnswersSchema = [
        {
            column: 'question_type',
            type: Number,
            value: (ua: UserAnswer) => ua.question_type
        },
        {
            column: 'question_id',
            type: Number,
            value: (ua: UserAnswer) => ua.question_id
        },
        {
            column: 'question_title',
            type: String,
            value: (ua: UserAnswer) => ua.question_title
        },
        {
            column: 'answer_id',
            type: Number,
            value: (ua: UserAnswer) => ua.answer_id
        },
        {
            column: 'answer_order',
            type: Number,
            value: (ua: UserAnswer) => ua.answer_order
        },
        {
            column: 'answer_title',
            type: String,
            value: (ua: UserAnswer) => ua.answer_title
        },
        {
            column: 'answer_ref_id',
            type: Number,
            value: (ua: UserAnswer) => ua.answer_ref_id
        },
        {
            column: 'indicator_name',
            type: String,
            value: (ua: UserAnswer) => ua.indicator_name
        },
        {
            column: 'indicator_id',
            type: Number,
            value: (ua: UserAnswer) => ua.indicator_id
        },
        {
            column: 'final_score',
            type: String,
            value: (ua: UserAnswer) => ua.final_score
        },
        {
            column: 'ranked_score',
            type: Number,
            value: (ua: UserAnswer) => ua.ranked_score
        },
        {
            column: 'question_description',
            type: Number,
            value: (ua: UserAnswer) => ua.question_description
        },
        {
            column: 'answer_description',
            type: Number,
            value: (ua: UserAnswer) => ua.answer_description
        },
        {
            column: 'assign_id',
            type: Number,
            value: (ua: UserAnswer) => ua.assign_id
        },
        {
            column: 'assessment_id',
            type: Number,
            value: (ua: UserAnswer) => ua.assessment_id
        },
        {
            column: 'answer_value',
            type: String,
            value: (ua: UserAnswer) => ua.answer_value
        },
        {
            column: 'score_value',
            type: Number,
            value: (ua: UserAnswer) => ua.score_value
        },
        {
            column: 'answer_weight',
            type: String,
            value: (ua: UserAnswer) => ua.answer_weight
        },
    ]
    await writeXlsxFile([userAnswers], {
        schema: [userAnswersSchema],
        sheets: ['userAnswers'],
        stickyRowsCount: 1,
        fileName
    })
}
