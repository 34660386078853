import moment from "moment";
import { useEffect, useState } from "react"
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Typography, TextField, InputLabel } from "@mui/material"

import request from "./../../../RequestUtil";
import { getAssessmentTypes } from "../../../services/assessment-types";
import BasicTextInput from "../../../Components/Inputs/BasicTextInput"

const columns = [
  { id: 'indicator_name', label: 'Indicator Name', minWidth: 200 },
  { id: 'indicator_identifier', label: 'Indicator Identifier', minWidth: 200 },
  { id: 'likert_mean', label: 'Mean', minWidth: 50, align: 'right' },
  { id: 'likert_sd', label: 'Standard Deviation', minWidth: 50, align: 'right' },
];

const CreateNorm = ({ user }) => {
  const location = useLocation();
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [value, setValues] = useState({
    assessmentType: "1",
    version: null
  })

  const [norm, setNorm] = useState([]);
  const [normVersions, setNormVersions] = useState([]);
  const [assessmentTypes, setAssessmentTypes] = useState([]);

  useEffect(() => {
    const fetchNorms = async () => {
      const response = await fetch(`/api/scoring-constants?assessmentType=1`);
      const normsResponse = await response.json();
      const a = normsResponse?.rows[0]?.jsonData?.map(n => ({
        ...n,
        likert_mean: 0,
        likert_sd: 0,
      }));

      setNorm(a);
      setNormVersions(normsResponse.rows);
    };

    fetchNorms();
    if (assessmentTypes.length === 0) {
      const fetchAssessmentsTypes = async () => {
        const assessmentTypesResponse = await getAssessmentTypes({
          id: 1, // Right now we just allow for entry level
        });

        setAssessmentTypes(assessmentTypesResponse.rows);
      }
      fetchAssessmentsTypes();
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (e) => {
    setValues(p => ({ ...p, [e.target.name]: e.target.value }))
  }

  const handleCancel = () => {
    history.push(`/admin/norms`);
  }

  const handleInputChange = (index, columnId, newValue) => {
    setNorm(prev => prev.map((row, ind) => index === ind ? { ...row, [columnId]: newValue } : row));
  };

  const handleCreate = async () => {
    try {
      setLoading(true);
      const data = {
        jsonData: norm.map(n => ({
          ...n,
          createdBy: user?.user_id,
          createdAt: moment(),
          updatedBy: user?.user_id,
          updatedAt: moment(),
        })),
        assessmentType: +value['assessmentType'],
        version: normVersions.length + 1,
      }

      const response = await request({
        method: "POST",
        url: `/api/scoring-constants`,
        data: data
      })
      if (response.status !== 200) throw new Error('Error in Creating');

      history.goBack();

    } catch (e) {
      console.log("e", e)
    } finally {
      setLoading(false)
    }
  }

  const isInvalidateForm = norm.some(row => {
    const {likert_mean, likert_sd} = row;
    return (
      (likert_mean !== 0 && !likert_mean) || 
      (likert_sd !== 0 && !likert_sd)
    );
  });

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ mb: 5 }}
      >
        <Typography variant="h4">
          Add New Norm
        </Typography>
        <div>
          <Button
            variant="contained"
            sx={{ marginRight: 1 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Grid>
      <Grid container spacing={2} >
        <Grid item xs={6}>
          <InputLabel id="assessmentType-label">Assessment Type</InputLabel>
          <Select
            labelId="assessmentType-label"
            id="assessment-type-select"
            name="assessmentType"
            value={value['assessmentType']}
            displayEmpty
            onChange={handleChange}
            sx={{ width: "100%" }}
          >
            {
              assessmentTypes.map((assessmentType) => {
                const { id, label } = assessmentType;
                return (
                  <MenuItem
                    key={id}
                    value={id}>
                    {label}
                  </MenuItem>
                )
              })
            }
          </Select>
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="version-label">Version</InputLabel>
          <TextField
            labelId="version-label"
            value={`Version ${normVersions.length + 1}`}
            disabled
            sx={{ width: "100%" }}
          />
        </Grid>
        {norm?.length && <Grid item xs={12} maxWidth={800}>
          <TableContainer >
            <Table >
              <TableHead sx={{ backgroundColor: "#5d2a5f", color: "#fff" }}>
                <TableRow>
                  {columns.map((column, ind) => (
                    <TableCell
                      key={column.id}
                      align={"left"}
                      sx={{
                        color: "#fff !important",
                        "&:hover": {
                          color: "#fff!important",
                        },
                        "&.Mui-active": {
                          color: "#fff !important",
                        },
                        "&.Mui-active svg": {
                          color: "#fff !important",
                        },
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {norm
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const actualIndex = page * rowsPerPage + index;
                    return (
                      <TableRow key={index} hover role="checkbox" tabIndex={-1} >
                        {columns.map((column) => {

                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={"justify"}>
                              {(column.id === 'likert_mean' || column.id === 'likert_sd') ?

                              <BasicTextInput
                                title={column.label}
                                hideTitle={true}
                                placeholder={`Enter ${column.label}`}
                                name={column.label}
                                value={value}
                                onChange={(e) => handleInputChange(actualIndex, column.id, e.target.value)}
                                style={{ mb: 1 }}
                                type={'number'}
                              /> :
                                value
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={norm.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>}
        {
          norm?.length ? <Grid item xs={12} sx={{ display: 'flex', justifyContent: "flex-end" }}>
            <Button

              variant="contained"
              size="large"
              sx={{ marginRight: 1, width: 200 }}
              onClick={handleCreate}
              disabled={loading || isInvalidateForm}
            >
              {loading ? "Loading..." : "Create"}
            </Button>
          </Grid> : null
        }

      </Grid>
    </>
  )
}

export default CreateNorm