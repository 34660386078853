const NotesBlock = ({ numLines = 12 }) => {
  const linesInt = parseInt(numLines);
  let lines = [];
  const line = (i) => {
    return <div className="line" key={i + 1}></div>;
  };
  for (let i = 0; i < linesInt; i++) {
    lines.push(line(i));
  }

  return (
    <>
    <strong>Notes:</strong>
      <div className="notes-block">{lines}</div>
    </>
  );
};

export default NotesBlock;
