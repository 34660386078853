export async function getSignedUrl(id) {
  const response = await fetch(`/api/report/generate-signed-url/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });

  const data = await response.json();
  return data;
}