import axios from "axios";

export async function updateItem(data = {}) {
  return axios.put("/api/item-bank", data);
}

export async function getItemHistory(itemId, params = {}) {
  const response = await fetch(
    `/api/items/`+ itemId + `/history` + new URLSearchParams(params)
  );
  const data = await response.json();
  return data;
}