import { me, logout as authLogout } from "../../services/auth";

export const fetchUserSession = () => async (dispatch) => {
  console.log("[REDUX action called] [fetchUserSession]");
  try {
    const user = await me();
    dispatch({
      type: "SET_USER_SESSION",
      payload: { user },
    });
  } catch(error) {
    console.log("error: ", error);
  }
}

export const setUserSession = (user) => async (dispatch) => {
  console.log("[REDUX action called] [setUserSession]");
  dispatch({
    type: "SET_USER_SESSION",
    payload: { user },
  });
}

export const logout = () => async (dispatch) => {
  console.log("[REDUX action called] [logout]");
  try {
    await authLogout();
    dispatch({
      type: "SET_USER_SESSION",
      payload: { user: null },
    });
  } catch(error) {
    console.log("error: ", error);
  }
}