import React from "react";
import {useHistory} from "react-router-dom";
import {useMutation} from "react-query";


export const DebugMain = () => {
    const history = useHistory();
    const addQuestionsMutation = useMutation(body => {
        console.log("fetching with body", body)
        return fetch('/api/admin/debug/assessment/addQuestions', {  // URL to the API endpoint
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
    })
    const markAssignmentIncompleteMutation = useMutation(assignment_id => {
        return fetch(`/api/admin/debug/assignment/${assignment_id}/incomplete`, {  // URL to the API endpoint
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
    })
    const createNewVersionMutation = useMutation(assessment_id => {
        return fetch(`/api/admin/debug/assessment/${assessment_id}/new-version`, {  // URL to the API endpoint
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
    })

    function toAssignment(assignment_id: string) {
        history.push(`/admin/debug/assignment/${assignment_id}`)
    }

    function toAssessment(assessment_id: string) {
        history.push(`/admin/debug/assessment/${assessment_id}`)
    }

    function deleteAssessment(assessment_id: string) {
        alert("Delete assessment " + assessment_id)
    }

    function addQuestions(contents: string) {
        const json = JSON.parse(contents)
        addQuestionsMutation.mutate(json)
    }

    function becomeUser(email: string) {
        alert("Become user " + email)
    }

    return (
        <div>
            <FormField label={'View Assignment ID'} placeholder={'assignment_id'} onSubmit={toAssignment}/>
            <FormField label={'Mark Assignment Incomplete'} placeholder={'assignment_id'} onSubmit={markAssignmentIncompleteMutation.mutate}/>
            <FormField label={'View Assessment ID'} placeholder={'assessment_id'} onSubmit={toAssessment}/>
            <FormField label={'Create new assessment version for assessment_id'} placeholder={'assessment_id'} onSubmit={createNewVersionMutation.mutate}/>
            {/*<FormField label={'Become user'} placeholder={'email address'} onSubmit={becomeUser}/>*/}
            {/*<FormField label={'Delete DebugAssessment'} onSubmit={deleteAssessment}/>*/}
            <AddQuestionsField label={'Add Questions'} onSubmit={addQuestions}/>
        </div>
    )

};

function AddQuestionsField({label, onSubmit}) {
    const [value, setValue] = React.useState("")
    return (
        <div style={{padding: '1rem'}}>
            <form>
                <label style={{paddingRight: '1rem'}}>{label}</label>
                <textarea onChange={e => setValue(e.target.value)}/>
            </form>
            <button onClick={() => {
                onSubmit(value)
            }}>Submit
            </button>
        </div>
    )

}

function FormField({label, onSubmit, placeholder ="ID"}) {
    const [value, setValue] = React.useState("")
    return (
        <div style={{padding: '1rem'}}>
            <form onSubmit={(e) => {
                e.stopPropagation()
                e.preventDefault()
                console.log("submit", JSON.stringify({value}))
                onSubmit(value)
            }}>
                <label style={{paddingRight: '1rem'}}>{label}</label>

                <input type={"text"} placeholder={placeholder} onChange={e => setValue(e.target.value)}
                       value={value}/>
            </form>
        </div>
    )

}

type CSV = {
    question: string,
    answers: { answer: string, indicator: string }[]
}

/**
 * probably won't keep this but it's good for turning this kind of spreadsheet into JSON we can upload as questions
 * https://voltapeople.sharepoint.com/:x:/r/_layouts/15/Doc.aspx?sourcedoc=%7B556922DD-568B-4BFA-8CCB-1FCEAA36E62B%7D&file=2023%20Lateral%20SJTs%20-%20Final%20-%2012%205%202023.xlsx&fromShare=true&action=default&mobileredirect=true
 *
 * @param csv
 */
function csvAssessmentToJson(csv: string) {
    const rows = csv.split('\n'); // Split the input into rows
    const results: CSV[] = [];
    let currentQuestion: CSV

    rows.forEach((row, index) => {
        if (index === 0) return; // Skip the header row

        const fields = [];
        let field = '';
        let inQuotes = false;

        for (let i = 0; i < row.length; i++) {
            const char = row[i];
            if (char === '"') {
                if (inQuotes && row[i + 1] === '"') {
                    field += '"';
                    i++;
                } else {
                    inQuotes = !inQuotes;
                }
            } else if (char === ',' && !inQuotes) {
                fields.push(field);
                field = '';
            } else {
                field += char;
            }
        }
        fields.push(field); // Add the last field after the loop

        if (fields[0]) { // This row is a new question
            currentQuestion = {question: fields[0], answers: []};
            results.push(currentQuestion);
        }

        // Add answers to the current question
        if (fields[1] && currentQuestion) {
            currentQuestion.answers.push({
                answer: fields[1],
                indicator: fields[2] || "" // Handle missing indicators safely
            });
        }
    });

    return results;

}

