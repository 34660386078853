import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import {
    IconButton,
    Link,
    Menu,
    MenuItem,
    Typography,
} from "@mui/material";
import SortableTable from "../../../Components/SortableTable";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loader from "../../../Components/Loader";
import { getScoringConstants } from "../../../services/scoring-constants";
import { getIndicators } from "../../../services/indicators";

import UpdateNormModal from "./UpdateNormModal";

const ITEM_HEIGHT = 48;

const NormTable = forwardRef(({
    scoringConstantId, showEditOptions = false, user 
}, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [norms, setNorms] = useState({});
    const [selectedNorm, setSelectedNorm] = useState({});
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const history = useHistory();

    useEffect(async () => {
        if (scoringConstantId) {
            setIsLoading(true);
            const response = await getScoringConstants({
                constId: scoringConstantId
            });

            const indicatorResponse = await getIndicators({
                indicatorType: 1,
            });

            const indicatorMap = _.keyBy(indicatorResponse.rows, 'indicatorId');

            const norms = _.get(response, 'rows.0', {});
            norms.jsonData.forEach(row => {
                const indicator = indicatorMap[row.indicator_id];
                // Right now BE database have stale names, so we re-populate names here
                row.indicator_name = indicator?.indicatorName;
            })

            setNorms(norms);
            setIsLoading(false);
        }
    }, [scoringConstantId]);

    useImperativeHandle(ref, () => ({
        getNorms() {
          return norms;
        }
    }));
    
    const getColumns = () => {
        const columns = [
            {
                id: "indicator_id",
                disablePadding: false,
                label: "Indicator ID",
                width: 150,
                isVisible: () => false,
                getValue: (row) => row.indicator_id,
                render: (row) => row.indicator_id,
            },
            {
                id: "indicator_name",
                numeric: false,
                disablePadding: false,
                label: "Indicator Name",
                width: 150,
                isVisible: () => true,
                getValue: (row) => row.indicator_name,
                render: (row) => row.indicator_name,
            },
            {
                id: "indicator_identifier",
                numeric: false,
                disablePadding: false,
                label: "Indicator Identifier",
                width: 150,
                isVisible: () => true,
                getValue: (row) => row.indicator_identifier,
                render: (row) => row.indicator_identifier,
            },
            {
                id: "likert_mean",
                numeric: false,
                disablePadding: false,
                label: "Mean",
                width: 150,
                isVisible: () => true,
                getValue: (row) => row.likert_mean,
                render: (row) => row.likert_mean,
            },
            {
                id: "likert_sd",
                numeric: false,
                disablePadding: false,
                label: "Standard Deviation",
                width: 150,
                isVisible: () => true,
                getValue: (row) => row.likert_sd,
                render: (row) => row.likert_sd,
            },
        ];

        if(showEditOptions) {
            columns.push({
                id: "edit",
                numeric: false,
                disablePadding: false,
                label: "",
                width: 80,
                isVisible: () => true,
                getValue: (row) => row.edit,
                render: (row, i) => (
                    <SearchTableMenu
                        row={row}
                        handleEdit={(row) => {
                            setIsEditModalOpen(true);
                            setSelectedNorm(row);
                        }}
                    />
                ),
            });
        } else {
            columns.push({
                id: "edit",
                numeric: false,
                disablePadding: false,
                label: "",
                width: 80,
                stopEventPropagation: true,
                isVisible: () => true,
                getValue: (row) => row.edit,
                render: (row, i) => (
                    <Link onClick={() => {
                        return history.push(`/admin/norms?version=${norms.version}`)
                    }}>
                        <Typography variant="inherit" noWrap>
                            Visit Norms Page
                        </Typography>
                    </Link>
                ),
            });
        }

        return columns.filter((cell) => cell.isVisible());
    };

    return (
        <>
            {
                isLoading ?
                <Loader minHeight={"70vh"} /> :
                <SortableTable
                    columns={getColumns()}
                    rows={norms.jsonData || []}
                    title={""}
                    defaultOrderBy={"indicator_name"}
                    setSelected={() => { }}
                    showSortableTableToolbar={false}
                />
            }
            {
                isEditModalOpen &&
                <UpdateNormModal
                    setIsEditModalOpen={setIsEditModalOpen} 
                    norm={selectedNorm} 
                    norms={norms}
                    setNorms={setNorms}
                    user={user} 
                />
            }
        </>
    );
});

const SearchTableMenu = ({ row, handleEdit }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick} id="search-menu-button">
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="search-menu"
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: "29ch" } }}
            >
                <MenuItem
                    selected={false}
                    onClick={() => {
                        handleClose();
                        handleEdit(row);
                    }}
                >
                    <Typography variant="inherit" noWrap>
                        Edit Norm
                    </Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default NormTable;
