import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const boxStyle = {
  borderRadius: "13px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 0,
  width: "fit-content",
  maxHeight: "80vh",
  maxWidth: "80vw",
  overflow: "overlay",
  outline: "none!important",
  "::-webkit-scrollbar": {
    width: "12px",
    opacity: 0.5,
    padding: "5px",
    marginRight: "5px",
  },
  "::-webkit-scrollbar-track:hover": {
    backgroundColor: "rgba(224, 224, 224, .8)",
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: "16px",
  },
  "::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a0a0a5",
    border: "3px solid #f4f4f4",
  },
  "&:hover": {
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#a0a0a5",
      border: "3px solid #f4f4f4",
    },
  },
  "::-webkit-scrollbar-button": {
    display: "none",
  },
};

const EditModal = ({
  children,
  handleCancel,
  title,
  subTitle,
  isBlocking = false,
  style,
  contentPaddingLeft = 3.5,
  minWidth,
}) => {
  return (
    <Modal
      style={style}
      open
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      onClose={(event, reason) => {
        if (reason && reason == "backdropClick" && isBlocking) return;
        handleCancel();
      }}
      closeAfterTransition
      disableBackdropClick={isBlocking}
      disableEscapeKeyDown={isBlocking}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Box sx={{ ...boxStyle, minWidth }}>
          <Box
            sx={{
              width: "100%",
              pl: 2,
              pr: 2,
              maxWidth: "90%",
              margin: "auto",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h5"
              sx={{ textAlign: "center", pt: 3.5, fontWeight: "bold" }}
            >
              {title}
            </Typography>

            {subTitle && (
              <Typography
                id="modal-modal-sub-title"
                variant="subtitle1"
                sx={{
                  textAlign: "center",
                  pt: 0.5,
                  color: "rgba(17, 17, 17, 0.7)",
                }}
              >
                {subTitle}
              </Typography>
            )}

            {!isBlocking && (
              <IconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "90%",
                  color: "#fff",
                }}
                onClick={() => handleCancel()}
                aria-label="delete"
              >
                <CloseIcon sx={{ fontSize: "30px", width: "1.5rem" }} />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              "& > :not(style)": { width: "100%", marginTop: 2 },
              display: "felx",
              flexDirection: "column",
              p: 4,
              pt: 1,
            }}
            noValidate
            autoComplete="off"
          >
            <Box sx={{ width: "fit-content", paddingLeft: contentPaddingLeft }}>
              {children}
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditModal;
