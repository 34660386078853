import React, { Component } from "react";

class MultiCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // method: handle check
  // method: handle uncheck

  render() {
    let list = this.props.items.map((item, i, arr) => {
      return (
        <li key={i}>
          <button
            className={item.selected ? "button green" : "button"}
            onClick={() => {
              this.props.handleCheck(item.answer_id);
            }}
          >
            {item.selected ? "Unselect" : "Select"}
          </button>
          <span>{item.answer_title}</span>
        </li>
      );
    });
    return (
      <div className="checklist-contain">
        <ul>{list}</ul>
      </div>
    );
  }
}

export default MultiCheck;
