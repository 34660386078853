import React, { Component } from "react";

class Paginator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: null || 1,
      visiblePages: [1, 2, 3],
      pageSize: this.props.pageSize,
    };

    this.totalPages = Math.ceil(this.props.length / 10);

    this.handleBack = this.handleBack.bind(this);
    this.handleForward = this.handleForward.bind(this);

    this.pageNeighbors = [1, 2, 3];
  }

  handleBack() {
    if (this.state.currentPage > 1) {
      console.log(this.state.currentPage - 1);
      this.setState({ currentPage: this.state.currentPage - 1 });
    } else {
      console.log(this.totalPages);
    }
    console.log("back event");
  }

  handleForward() {
    console.log("fwd event");
    if (this.state.currentPage < this.props.length) {
      console.log(this.state.currentPage + 1);
      this.setState({ currentPage: this.state.currentPage + 1 });
    } else {
      console.log("do nothing");
    }
    console.log("fwd event");
  }

  render() {
    let pageNeighborsButtons = this.pageNeighbors.map((item, i, arr) => {
      return (
        <button
          className="button green"
          onClick={() => {
            this.goToPage(item);
          }}
        >
          {item}
        </button>
      );
    });

    return (
      <div className="pagination">
        <button className="button green" onClick={this.handleBack}>
          Back
        </button>
        {pageNeighborsButtons}
        <button className="button green" onClick={this.handleForward}>
          Next
        </button>
      </div>
    );
  }
}

export default Paginator;
