export const openSnackbar = (snackbar) => async (dispatch) => {
  console.log("[REDUX action called] [openSnackbar]");
  dispatch({
    type: "OPEN_SNACKBAR",
    payload: { snackbar },
  });
};

export const closeSnackbar = () => async (dispatch) => {
  console.log("[REDUX action called] [closeSnackbar]");
  dispatch({
    type: "CLOSE_SNACKBAR",
    payload: {},
  });
};
