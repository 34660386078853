import axios from "axios";

// Todo - move this to a common place later
axios.defaults.withCredentials = true;

export const getFirm = () => {
  return axios.get(`/api/firm`, { withCredentials: true })
    .then(response => {
        console.log("response: ", response);
        return response.data;
    });
}

export async function getFirmClean(params) {
  const response = await fetch(
    `/api/firm/clean?` + new URLSearchParams(params)
  );
  const data = await response.json();

  return data;
}

export async function updateFirm(id, data = {}) {
  const response = await fetch(`/api/firm/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response;
}
