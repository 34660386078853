import DimensionsKey from "./DimensionsKey";

const _ = require("lodash");

const CompetencyOverview = (props) => {
  const competenciesByDimension = _.orderBy(props.competencies, [
    "dimensionName",
    "indicatorName",
  ]);

  const children = competenciesByDimension.map((row, i) => {
    const bgColorClass = "bg-" + row.color;
    const widthProp = row.individualScore + "%";
    return (
      <div className="row" key={i + 1}>
        <div className="label">{row.indicatorName}</div>
        <div className="bar-contain">
          <div
            className={"bar-inner " + bgColorClass}
            style={{ width: widthProp }}
          ></div>
        </div>
        <div className="level">{row.levelLabel}</div>
      </div>
    );
  });

  const keyItems = props.dimensions.map((row, i) => {
    const bgColorClass = "bg-" + row.color;
    return (
      <div className="item" key={i + 1}>
        <div className={"bullet " + bgColorClass}> </div>
        <div className="label">{row.name}</div>
      </div>
    );
  });
  return (
    <div className="competency-overview">
      <DimensionsKey dimensions={props.dimensions} />

      <div className="competency-overview-chart-contain">
        <div className="legend-contain">
          <div className="label">0</div>
          <div className="label">20</div>
          <div className="label">40</div>
          <div className="label">60</div>
          <div className="label">80</div>
          <div className="label">100</div>
        </div>
        <div className="competency-overview-chart-lines">
          <div className="lines-contain">
            <div className="line" />
            <div className="line" />
            <div className="line" />
            <div className="line" />
            <div className="line" />
          </div>
        </div>
        <div className="competency-overview-chart-inner">{children}</div>
      </div>
      <div className="footnote">
        Opportunity (x&lt;30), proficiency (30&le;x&lt; 75), or core strength
        (x&ge;75).
      </div>
    </div>
  );
};

export default CompetencyOverview;
