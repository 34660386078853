import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SurveyPage from "./SurveyPage";

const Survey = () => {
  const [surveyStatus, setSurveyStatus] = useState({
    status: "",
    email: "",
    code: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    const fetchSurveyStatus = async () => {
      const response = await fetch(`/api/survey/${params.id}`);

      if (!(response.status === 200)) {
        setIsLoading(false);
        // setting an invalid status to show that code entered is incorrect or an error occurred
        setSurveyStatus((s) => ({ ...s, status: "invalid" }));
        return;
      }

      const data = await response.json();

      if (data.length === 0) {
        setIsLoading(false);
        // setting an invalid status to show that code entered is incorrect or an error occurred
        setSurveyStatus((s) => ({ ...s, status: "invalid" }));
        return;
      }

      setSurveyStatus((s) => ({
        ...s,
        status: data[0].status,
        email: data[0].email,
        code: data[0].code,
      }));
      setIsLoading(false);
    };
    fetchSurveyStatus();
  }, [params.id]);

  return (
    <Box>
      <SurveyPage
        isLoading={isLoading}
        status={surveyStatus.status}
        code={surveyStatus.code}
        setStatus={(newStatus) =>
          setSurveyStatus((s) => ({ ...s, status: newStatus }))
        }
        setIsLoading={setIsLoading}
      />
    </Box>
  );
};

export default Survey;
