import {
  Divider,
  Paper,
  styled,
  Typography,
  Link as MuiLink,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { assessmentValues } from "../config/AppConst.js";

export default function OCIInstructions({ onClick, assessmentType = 0 }) {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    lineHeight: "60px",
  }));

  const titleContainerStyles = {
    width: "100%",
    backgroundColor: "#ecf6fc",
    paddingTop: 2,
    paddingBottom: 1,
    paddingLeft: 5,
    paddingRight: 5,
  };

  const titleStyles = {
    color: "#53585c",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "bold",
    textAlign: "left",
  };

  const bodyStyles = {
    textAlign: "left",
    maxWidth: 900,
  };

  const bodyContainerStyles = {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 5,
  };

  return (
    <Item elevation={2} variant="elevation">
      <Box sx={titleContainerStyles}>
        <Typography
          sx={titleStyles}
          variant="subtitle1"
          gutterBottom
          component="div"
        >
          How Does Thine’s Assessment Work?
        </Typography>
      </Box>
      <Box sx={bodyContainerStyles}>
        {assessmentValues.entryLevel.includes(assessmentType) ? (
          <Typography sx={bodyStyles} variant="body1" gutterBottom>
            You will be presented with a number of statements that may or may
            not describe you, your motivations, preferences, and/or tendencies.
            We will ask you to indicate to what extent you agree or disagree
            with the statement presented, or how often you engage in a
            particular behavior. You should be as honest as possible and rely on
            your initial reaction. Try not to think too much about each
            statement.
          </Typography>
        ) : null}
        {assessmentValues.altOci.includes(assessmentType) ? (
          <Typography sx={bodyStyles} variant="body1" gutterBottom>
            Thine’s assessment was developed to uncover how you typically
            approach work and provide insight that can guide your professional
            development. The assessment measures our Modern Lawyer Competency
            Framework, which was designed specifically for and in collaboration
            with leading lawyers and others familiar with the industry. We
            encourage you to consider the assessment as an
            opportunity to discover your core strengths and areas in which you
            can develop.
            <br />
            <br />
            The assessment consists of 180 statements and typically takes 15
            minutes to complete.
          </Typography>
        ) : null}
        {assessmentValues.lateral.includes(assessmentType) ? (
          <Typography sx={bodyStyles} variant="body1" gutterBottom>
            In this assessment, you will first be presented with a number of
            statements that may or may not describe you, your motivations,
            preferences, and/or behavioral tendencies. Some of these questions
            will ask you to indicate to what extent you agree or disagree with
            the statement presented. Others will ask how often you engage in a
            particular behavior. And, others will present you with two
            statements and ask you to indicate how you typically think with
            regard to those statements.
            <br />
            <br />
            After these statements, you will then be presented with a number of
            scenarios that you might encounter as a lateral associate. For each
            of these scenarios, you will be presented with multiple choices that
            may represent how you would respond in that situation. <br />
            <br />
            For all questions, please be as honest as possible and rely on your
            initial reaction when responding. Try not to think too much about
            each statement.
          </Typography>
        ) : null}
        {assessmentValues.integration.includes(assessmentType) ? (
          <Typography sx={bodyStyles} variant="body1" gutterBottom>
            In this assessment, you will be presented with a number of
            statements that allow you to describe your experience with, and
            exposure to, different skills and tasks related to the practice area
            in which you will be working.
            <br />
            <br />
            Please be as honest as possible. Try not to think too much about
            each statement. Your responses will be instrumental in helping your
            new firm to integrate you as effectively as possible.
          </Typography>
        ) : null}
        {assessmentValues.developmental.includes(assessmentType) ? (
          <Typography sx={bodyStyles} variant="body1" gutterBottom>
            Thine’s assessment measures for how you approach work and provides
            insight into typical behaviors, preferences, and priorities that can
            increase awareness and guide your professional development. The
            assessment measures for 22 professional competencies contained in
            our Modern Lawyer Competency Framework, which was designed
            specifically for and by lawyers with input from legal industry
            insiders. We encourage you to consider the assessment and the report
            as an opportunity to discover your core strengths and where you can
            develop.
            <br />
            <br />
            The assessment typically takes 20-25 minutes to complete.
          </Typography>
        ) : null}
      </Box>
      {assessmentValues.entryLevel.includes(assessmentType) ? (
        <>
          <Box sx={titleContainerStyles}>
            <Typography
              sx={titleStyles}
              variant="subtitle1"
              gutterBottom
              component="div"
            >
              After the Assessment
            </Typography>
          </Box>
          <Box sx={bodyContainerStyles}>
            <Typography sx={bodyStyles} variant="body1" gutterBottom>
              When you complete the assessment, you will have access to a
              development report that highlights your top strengths and top
              development priorities. This report can be used to help you
              prepare for interviews and set professional development goals.
            </Typography>
          </Box>
        </>
      ) : null}
      <Box sx={titleContainerStyles}>
        <Typography
          sx={titleStyles}
          variant="subtitle1"
          gutterBottom
          component="div"
        >
          Accommodation
        </Typography>
      </Box>
      <Box sx={bodyContainerStyles}>
        <Typography sx={bodyStyles} variant="body1" gutterBottom>
          Thine is committed to providing access and reasonable accommodation to
          ensure everyone can complete our assessment.
          <br />
          <br />
          To request an accommodation, please contact us at{" "}
          <MuiLink href="mailto:helpdesk@thine.co">helpdesk@thine.co</MuiLink>.
        </Typography>
      </Box>
      <Box sx={titleContainerStyles}>
        <Typography
          sx={titleStyles}
          variant="subtitle1"
          gutterBottom
          component="div"
        >
          Privacy Policy
        </Typography>
      </Box>
      <Box sx={bodyContainerStyles}>
        <Typography sx={bodyStyles} variant="body1" gutterBottom>
          We will not share any of your individual responses to any of the
          following statements with any firm or other organization.
          <br />
          <br />
          Please click here to view our{" "}
          <MuiLink href="https://www.thine.co/privacy-policy" target="_blank">
            Privacy Policy
          </MuiLink>
          .
        </Typography>
      </Box>
      <Divider></Divider>
      <Box
        sx={{
          width: "100%",
          display: "inline-flex",
          flexDirection: "row",
          justifyContent: "right",
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={onClick}
        >
          Begin
        </Button>
      </Box>
    </Item>
  );
}
