import _ from "lodash";
import React, { Component } from "react";
import { getSignedUrl } from "../services/report";
import Dialog from '@mui/material/Dialog';

class FileStoreReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      reportSignedUrl: null,
    }
  }

  async componentDidMount() {
    const { id } = this.props;
    try {
      const response = await getSignedUrl(id);
      this.setState({
        reportSignedUrl: _.get(response, "url", null),
      });
    } catch(error) {
      console.log(error);
    }
  }

  render() {
    const { reportSignedUrl } = this.state;

    return (
      <Dialog
        fullScreen
        open={true}
      >
        {
          reportSignedUrl &&
          <embed wmode="transparent" src={reportSignedUrl} width="100%" height="100%" />
        }
      </Dialog>
    );
  }
}

export default FileStoreReport;
