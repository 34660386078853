import "./Assessment.css";
import React, { Component } from "react";

import Question from "./Question";
import Paginator from "../Utilities/Paginator";
import request from "../../RequestUtil.js";

class Assessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meta: {
        title: null,
        description: null,
      },
      stage: 0,
      position: 0,
      questions: [],
    };

    this.nextQuestion = this.nextQuestion.bind(this);
    this.answerQuestion = this.answerQuestion.bind(this);
    this.prevQuestion = this.prevQuestion.bind(this);
    this.completeAssessment = this.completeAssessment.bind(this);
  }

  componentDidMount() {
    // get all the assignment data including assessment questions
    request({
      method: "post",
      url: "/api/assessments/user/assigned/take",
      data: {
        id: this.props.assign_id,
      },
    })
      .then(async (res) => {
        await this.setState({
          meta: res.data.meta,
          questions: res.data.questions,
        });
      })
      .catch((err) => console.log(err));
  }

  answerQuestion(answer) {}

  nextQuestion() {
    let update = {};
    switch (this.state.stage) {
      case 0:
        update = {
          stage: 1,
          position: 0,
        };
        break;
      case 1:
        if (this.state.position >= this.state.questions.length - 1) {
          update = {
            stage: 2,
          };
        } else {
          update = {
            position: this.state.position + 1,
            stage: 1,
          };
        }
        break;
      default:
        update = {
          stage: 0,
          position: 0,
        };
    }

    this.setState({
      ...this.state,
      stage: update.stage,
      position: update.position,
    });
  }

  prevQuestion() {
    let update = {};
    if (this.state.position === 0) {
      update = {
        stage: 0,
        position: 0,
      };
    } else {
      update = {
        stage: 1,
        position: this.state.position - 1,
      };
    }

    this.setState({
      ...this.state,
      stage: update.stage,
      position: update.position,
    });
  }

  completeAssessment() {
    if (this.state.stage === 2) {
      let complete = {
        assignment_id: this.props.assign_id,
      };

      request({
        method: "post",
        url: "/api/assessments/user/assigned/complete",
        data: complete,
      })
        .then((response) => {
          this.setState({ stage: 3 });
        })
        .catch((err) => console.log(err));
    }
  }

  render() {
    return (
      <div className="card">
        {this.state.stage === 0 ? (
          <div>
            <h3>{this.state.meta.assessment_title}</h3>
            <div>
              <p>{this.state.meta.assessment_description}</p>

              <button className="button" onClick={this.nextQuestion}>
                Begin Assessment
              </button>
              <button className="button">Back to List</button>
            </div>
          </div>
        ) : null}

        {this.state.stage === 1 ? (
          <div>
            <Question
              key={this.state.position}
              title={this.state.questions[this.state.position].question_title}
              description={
                this.state.questions[this.state.position].question_description
              }
              question_id={
                this.state.questions[this.state.position].question_id
              }
              assign_id={this.props.assign_id}
              nextQuestion={this.nextQuestion}
              answerQuestion={this.answerQuestion}
              prevQuestion={this.prevQuestion}
              question_type={
                this.state.questions[this.state.position].question_type
              }
            />
            <Paginator
              length={this.state.questions.length}
              pageSize="5"
              updatePosition={(update) => {
                console.log(update);
              }}
            />
          </div>
        ) : null}

        {this.state.stage === 2 ? (
          <div>
            <h3>Submit</h3>
            <div className="meta">
              <p>
                You have reached the end of the assessment. Please confirm that
                you are ready to submit, as you won't be able to go back and
                edit your responses afterwards.
              </p>
              <button
                className="button-primary"
                onClick={this.completeAssessment}
              >
                Yes, Submit
              </button>
              <button className="button-secondary">No, take me back</button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Assessment;
