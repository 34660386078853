const initialState = {
    open: false,
    message: "",
    severity: "error"
};

const snackbar = (state = initialState, { type, payload }) => {
    switch (type) {
        case "OPEN_SNACKBAR":
            return {
                ...payload.snackbar,
                open: true,
            };
        case "CLOSE_SNACKBAR":
            return { 
                ...state,
                open: false,
            };
        default:
            return state;
    }
}

export default snackbar;
